/* eslint-disable */ 
import { DateTime } from "luxon";
import { get } from "lodash";
import Vue from "vue";

const EventBus = new Vue();
export default {
    methods: {
        get,
        getFilteredFields(fieldset, label) {
            return fieldset?.filter((field) => field.source == label) || [];
        },
        getMasterFields(fieldset) {
            return fieldset?.filter((field) => !field.source || field.source == "complyadvantage") || [];
        },
        checkPepList(data) {
            let containsWarning = false;
            for (const [key, value] of Object.entries(data)) {
                if (value["aml_types"]?.includes("warning")) {
                    containsWarning = true;
                }
            }
            return containsWarning;
        },
        getNewsLink(source) {
            let UrlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
            if (source.includes("(https:")) {
                const rel = source.split("https:");
                const filter = rel.filter((ele) => ele.includes("https:"));
                if (filter.length > 0) return filter;
                else return source.match(/(https:[^"]+)/)[0];
            } else return source;
        },

        checkSanction(data) {
            let containsWarning = false;
            for (const [key, value] of Object.entries(data)) {
                if (value["aml_types"]?.includes("sanction")) {
                    containsWarning = true;
                }
            }
            return containsWarning;
        },
        checkPep4(data, pepClass) {
            let containsWarning = false;
            for (const [key, value] of Object.entries(data)) {
                if (value["aml_types"]?.includes(pepClass)) {
                    containsWarning = true;
                }
            }
            return containsWarning;
        },
        checkPepLength(data, pepClass) {
            let pepLength = 0;
            for (const [key, value] of Object.entries(data)) {
                if (value["aml_types"]?.includes(pepClass)) {
                    pepLength = pepLength+1;
                }
            }
            return pepLength;
        },
        checkComplyAdvantage(data)
        {
            let complyLength = 0;
            for (const [key, value] of Object.entries(data)) {
                if (value.name == 'ComplyAdvantage PEP Data')
                {
                    complyLength = complyLength+1;
                }
            }
            return complyLength; 
        },
        checkFitnessProbityList(data) {
            let containsWarning = false;
            for (const [key, value] of Object.entries(data)) {
                if (value["aml_types"]?.includes("fitness-probity")) {
                    containsWarning = true;
                }
            }
            return containsWarning;
        },
        checkAdverseMedia(data) {
            let containsWarning = false;
            for (const [key, value] of Object.entries(data)) {
                if (value["aml_types"]?.includes("adverse-media")) {
                    containsWarning = true;
                }
            }
            return containsWarning;
        },
        parseDate(dt, format = "EEEE, dd LLLL yyyy") {
            if(dt)
            return DateTime.fromISO(dt).toFormat(format);
            else
            return "";
        },
        getAdverseName(data_title) {
            return get(data_title, "associate.NameDetails.Name", [])
                .filter((e) => e["@NameType"] == "Primary Name")
                .map((ele) => `${get(ele, "NameValue[0].FirstName", "")} ${get(ele, "NameValue[0].MiddleName", "")} ${get(ele, "NameValue[0].Surname", "")} ${get(ele, "NameValue[0].MaidenName", "")} ${get(ele, "NameValue[0].EntityName", "")}`, false);
        },
        fetchMoreInfo(data, type, recall = true) {
            data[`${type}Expanded`] = !data[`${type}Expanded`];

            let allExpanded = false;
            switch (this.adverseCardKey) {
                case "complyAdvantage":
                    if (
                        (this.adverseCard.detailsComplyExpanded || !(this.getMasterFields(this.adverseCard?.doc?.fields)?.length > 0 && this.adverseCard.doc)) &&
                        (this.adverseCard.associatesComplyExpanded || !this.adverseCard?.doc?.associates) &&
                        (this.adverseCard.sanctionsComplyExpanded || !this.checkSanction(this.adverseCard?.doc?.source_notes)) &&
                        (this.adverseCard.warningComplyExpanded || !this.checkPepList(this.adverseCard?.doc?.source_notes)) &&
                        (this.adverseCard.pepDetailsExpanded || !(this.adverseCard.doc && (this.checkPep4(this.adverseCard.doc.source_notes, "pep-class-1") || this.checkPep4(this.adverseCard.doc.source_notes, "pep-class-2") || this.checkPep4(this.adverseCard.doc.source_notes, "pep-class-3") || this.checkPep4(this.adverseCard.doc.source_notes, "pep-class-3")||this.checkPep4(this.adverseCard.doc.source_notes)))) &&
                        (this.adverseCard.fitnessComplyExpanded || !this.checkFitnessProbityList(this.adverseCard?.doc?.source_notes)) &&
                        (this.adverseCard.imagesComplyExpanded || !this.adverseCard?.doc?.assets) &&
                        (this.adverseCard.adverseComplyExpanded || !(this.isMedia && this.getFilteredFields(this.adverseCard.doc?.fields, "complyadvantage-adverse-media").length > 0)) &&
                        (this.adverseCard.mediaComplyExpanded || !this.isMedia)
                    )
                        allExpanded = true;
                    else allExpanded = false;
                    break;
                case "worldCheck":
                    if (
                        (this.adverseCard.worldCheckExpanded || !this.adverseCard?.keywords?.length > 0) &&
                        this.adverseCard.aliasesExpanded &&
                        (this.adverseCard.citizenshipsExpanded || !get(this.adverseCard, "citizenship", false)) &&
                        (this.adverseCard.locationsExpanded || !(this.adverseCard.locations.length > 0)) &&
                        (this.adverseCard.companiesReportedExpanded || !(this.adverseCard.companiesReported.length > 0 && this.adverseCard.companiesReported)) &&
                        (this.adverseCard.reportedLinkedExpanded || !(this.adverseCard.reportedLinked.length > 0)) &&
                        (this.adverseCard.furtherInformationExpanded || !get(this.adverseCard, "further_information", false)) &&
                        (this.adverseCard.informationSourcesExpanded || !this.adverseCard.external_sources.length > 0)
                    )
                        allExpanded = true;
                    else allExpanded = false;
                    break;
                case "dowJones":
                    if (
                        (this.adverseCard.namesDowjonesExpanded || !(this.adverseCard.NameDetails.Name.length > 0)) &&
                        (this.adverseCard.countryDowjonesExpanded || !(this.adverseCard.CountryDetails.length > 0)) &&
                        (this.adverseCard.datesDowjonesExpanded || !(get(this.adverseCard, "DateDetails.Date", []).length > 0)) &&
                        (this.adverseCard.addressesDowjonesExpanded || !(this.getAddress(this.adverseCard.Address).length > 0 || get(this.adverseCard, "CompanyDetails", []).length > 0)) &&
                        (this.adverseCard.imagesDowjonesExpanded || !(get(this.adverseCard, "Images", false) || (this.adverseCard.Imagesget && this.adverseCard.Imagesget.length > 0))) &&
                        (this.adverseCard.rolesDowjonesExpanded || !(get(this.adverseCard, "RoleDetail", []).length > 0)) &&
                        (this.adverseCard.relativeCloseDowjonesExpanded || !(get(this.adverseCard, "associates", []).length > 0)) &&
                        (this.adverseCard.profileNotesDowjonesExpanded || !get(this.adverseCard, "ProfileNotes", false)) &&
                        (this.adverseCard.sourcesDowjonesExpanded || !(get(this.adverseCard, "SourceDescription.Source", "").length > 0)) &&
                        (this.adverseCard.listRefDowjonesExpanded || !(get(this.adverseCard, "SanctionsReferences", []).length > 0)) &&
                        (this.adverseCard.idNumDowjonesExpanded || !(get(this.adverseCard, "IDNumberTypes.ID", []).length > 0)) &&
                        (this.adverseCard.StakeLevelDetailssExpanded || !(get(this.adverseCard, "StakeLevelDetails", []).length > 0)) &&
                        (this.adverseCard.fdsCodeDetailsExpanded || !(get(this.adverseCard, "FDSCodeDetails", []).length > 0)) &&
                        (this.adverseCard.DJIIIndustryDetailssExpanded || !(get(this.adverseCard, "DJIIIndustryDetails", []).length > 0))
                    )
                        allExpanded = true;
                    else allExpanded = false;
                    break;
                default:
                    if (this.adverseCard.nodesExpanded || !this.adverseCard?.nodeTypes?.length) allExpanded = true;
                    else allExpanded = false;
                    break;
            }
            this.adverseCard.expandedPanel = allExpanded;

            // this.updateCollapse(type);
            this.$forceUpdate();
            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.expandedCard.api_all_data.doc_id !== this.expandedCard.api_all_data.doc_id);
                if (this.adverseCardKey === 'dowJones') {
                    const srcIndex = this.$parent.$refs[`${this.adverseCardKey}ComponentsRef`].findIndex((el) => el.source === this.source);
                    otherVersion = otherVersion.$refs[`${this.adverseCardKey}ComponentsRef`]?.[srcIndex];
                } else
                    otherVersion = otherVersion.$refs[`${this.adverseCardKey}ComponentsRef`];
                
                otherVersion.fetchMoreInfo(otherVersion.adverseCard, type, false);
            }
            setTimeout(() => {
                this.highlightChanges();
            }, 100);
        },
        handleExpandAll(isValue = null, recall = true) {
            let panels = {
                complyAdvantage: ["detailsComply", "associatesComply", "sanctionsComply", "warningComply", "pepDetails", "fitnessComply", "imagesComply", "adverseComply", "mediaComply"],
                worldCheck: ["worldCheck", "aliases", "citizenships", "locations", "companiesReported", "reportedLinked", "furtherInformation", "informationSources"],
                dowJones: ["namesDowjones", "countryDowjones", "datesDowjones", "addressesDowjones", "imagesDowjones", "rolesDowjones", "relativeCloseDowjones", "profileNotesDowjones", "sourcesDowjones", "listRefDowjones", "idNumDowjones", "StakeLevelDetailss", "fdsCodeDetails", "DJIIIndustryDetailss"],
                offshoreLeaks: ["nodes"],
            };
            if (isValue === true) {
                this.adverseCard.expandedPanel = true;
            } else {
                this.adverseCard.expandedPanel = !this.adverseCard.expandedPanel;
            }
            panels[this.adverseCardKey].forEach((ele) => {
                this.adverseCard[`${ele}Expanded`] = this.adverseCard.expandedPanel;
            });
            this.$forceUpdate();
            if (this.isComparisonModeOn && recall) {
                let otherVersion = this.$parent.$parent.$children.find((el) => el.expandedCard.api_all_data.doc_id !== this.expandedCard.api_all_data.doc_id);
                if (this.adverseCardKey === 'dowJones') {
                    const srcIndex = this.$parent.$refs[`${this.adverseCardKey}ComponentsRef`].findIndex((el) => el.source === this.source);
                    otherVersion = otherVersion.$refs[`${this.adverseCardKey}ComponentsRef`]?.[srcIndex];
                } else
                    otherVersion = otherVersion.$refs[`${this.adverseCardKey}ComponentsRef`];

                otherVersion.handleExpandAll(isValue, false);
            }
            if (this.isComparisonModeOn && !recall)
                setTimeout(() => {
                    this.highlightChanges();
                }, 100);
            this.$forceUpdate();
        },
        aliasNames(result,module_name="") {
            let result_data = [];
            const key= result.key || module_name
            switch (key) {
                case "complyAdvantage":
                    if (result?.api_all_data?.doc?.aka) {
                        result_data = result.api_all_data.doc.aka.map((alias) => alias.name);
                    }
                    if(result?.doc?.aka){
                        result_data = result.doc.aka.map((alias) => alias.name);
                    }
                    break;
                case "worldCheck":
                    result_data = result?.api_all_data?.aliases ?? [];
                    break;
                case "dowJones":
                    result_data = result?.api_all_data?.aka ?? [];
                    break;
                default:
                    break;
            }
           
            return result_data;
        },
    },
};
