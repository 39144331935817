<template>
  <div class="">
    <div
      class="border-2 border-gray-200 flex h-10 items-center rounded-lg w-74 justify-between px-4"
      :style="'background-color: #e7f1fb'"
    >
      <div class="flex w-full justify-between items-center">
        <div class="flex px-1 py-2 text-md">
          <span>{{ items.label }}</span>
        </div>
        <div class="flex justify-center px-1 py-2 w-16">
          <label class="">
            <SwitchButton
              :itemChecked="getCheckedValue"
              @input="handleToggleSwitch"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchButton from "./SwitchButton.vue";

export default {
  name: "check-box-card",
  props: {
    items: {
      type: Object,
      default: () => {},
      required: true,
    },
    type: {
      type: String,
      default: () => {},
      required: true,
    },
  },
  components: {
    SwitchButton,
  },
  computed: {
    getCheckedValue() {
      return this.items.checked;
    },
  },
  data() {
    return {};
  },
  methods: {
    handleToggleSwitch(val) {
      this.$emit("input", { value: val, id: this?.items?.id, type: this.type });
    },
  },
};
</script>
