<template>
  <div class="p-1 ml-2" :class="{ 'pl-2': data?.children?.length > 0, 'child-padding': !data?.children?.length }">
    <font-awesome-icon @click="toggleCollapse" :icon="['fas', !collapsed ? 'caret-down' : 'caret-right']" style="margin-left: -4px;" class="mr-2"
      v-if="data?.children?.length > 0" />
    <input type="checkbox" :checked="data.checked" v-model="data.checked" class="mr-2" />
    <span @click="toggleCollapse">{{ data.category }} ({{ data.count }})</span>
    <ul id="tc" v-show="!collapsed && data.children">
      <li :class="{ 'pls-2 cst-border': data?.children?.length > 0, 'child-padding': !data?.children?.length }"
        v-for="(child, index) in  data.children " :key="index">
        <TreeItem :data="child" ></TreeItem>
      </li>

    </ul>
  </div>
</template>
  
<script>
export default {
  name: 'TreeItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapsed: true,
    };
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
<style lang="scss" scoped>
ul#tc {

  li#tc.child-padding {
    padding-left: 25px !important;
  }
}

ul#tc:hover {
  background: transparent !important;
}

.child-padding {
  padding-left: 22px !important;
  margin-left: -4px;
}
.cst-border{
  
  border-left: 1px solid rgb(148, 145, 145);;
}
</style>