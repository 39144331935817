<template>
<div>


  <span class="unit-input">
    <span class="unit-input__prepend" v-if="prefixLabel">{{ prefixLabel }}</span>
    <input 
    :type="inputType" :disabled="disabled" :readonly="readOnly" :placeholder="placeholder" class=" unit-input__input " :class="{'pl-9': hasPreFixLabel, 'input-error': error}" :id="inputId" @change="$emit('change', $event)"  @input="onInput($event.target.value, mappedKey, placeholder)" :value="value" :title="value" >
    <span class="unit-input__append" v-if="appendLabel">{{ appendLabel }}</span>
  </span>
</div>
    
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: "text",
        },
        required: { type: Boolean, default: false },
        placeholder: {
            default: "",
            type: String
        },
        label: {
            type: String,
            default: ""
        },
        inputType: {
            type: String,
            default: 'number'
        },
        prefixLabel:{
            type: String,
            default: ""
        },
        appendLabel:{
            type: String,
            default: ""
        },
        value: {},
        borderFull: {},
        borderColor: {},
        changeCase: {},
        shadow: {
            default: true,
        },
        textLimit: {
            type: Number,
            default: -1,
        },
        disabled: Boolean,
        readOnly: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        mappedKey:{
            default: null
        },
        inputId: {
            type: String,
            default: ""
        },
        appendHandleEvent:{
            type: Function
        }
    },
  
    methods: {
        onInput(newValue, mappedKey, placeholder) {
            if (this.textLimit !== -1) {
                let val = newValue.slice(0, this.textLimit - 1);
                this.$emit("input", val, mappedKey, placeholder);
            } else {
                this.$emit("input", newValue, mappedKey, placeholder);
            }
        },
    },
    computed:{
        hasPreFixLabel(){
            return !!this.preFixLabel
        }
    }

}
</script>
<style>
.unit-input {
  border: #DFDFDF solid 1px;
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
  font-family: sans-serif;
  width: 100%;
}

.unit-input__input {
  border: 0;
  padding: .5em;
  font-size: .875rem;
  --tw-bg-opacity: 1;
background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1));
border-width: 1px;
border-radius: var(--rounded-btn, .5rem);
transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
transition-duration: .2s;
transition-timing-function: cubic-bezier(.4, 0, .2, 1);
height: 3rem;
line-height: 2;
flex-shrink: 1;
  width: 100%;
}

.unit-input__input:focus {
  outline: none !important;
  outline-offset: 2px;
box-shadow: none !important;
--tw-border-opacity: 1;
  border-color: hsla(var(--p) / var(--tw-border-opacity));

}

.unit-input__prepend,
.unit-input__append {
  background: #F4F4F4;
  padding: .5em;
  border: #DFDFDF solid 0;
  flex-grow: 0;
}

.unit-input__prepend {
  border-right-width: 1px;
}

.unit-input__append {
  border-left-width: 1px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>