<template>
    <div class="scroll overflow-y-scroll" style="max-height:80vh;" ref="news">
      <div class="text-xl font-medium  flex flex-col ">
        <!-- <div class="text-primary"> {{ detailsData?.entity}}</div> -->
        <div>
                   <a v-if='detailsData.source_name ==	"judyrecords" && !detailsData.entity_meta_data.is_details_scraped' class="text-black header-link" :href="detailsData?.url"  target="_blank">{{ detailsData.entity_meta_data.jurisdiction}}</a>
                   <a v-else class="text-black header-link"  target="_blank">{{ detailsData?.entity_meta_data.case_name }}</a>
        </div>
        <div class="flex">
                <span class="text_heading">Jurisdiction :</span>
                <span class="text_value mx-2">
                    {{detailsData?.entity_meta_data?.jurisdiction}}
                </span>
                <span class="text_heading">Case Type :</span>
                <span class="text_value mx-2">
                    {{detailsData?.entity_meta_data?.case_type || "-"}}
                 </span>
              </div>
              <div class="flex p-0 m-0">
                <div class="border-r-2 mr-3">
                  <span class="text_heading">Case Number :</span>
                  <span class="text_value ml-2 mr-4">
                    {{detailsData?.entity_meta_data?.case_number || "-"}}
                </span>
                </div>
                <div class="border-r-2 mr-3">
                  <span class="text_heading">Filling Date :</span>
                  <span class="text_value ml-2 mr-4">
                    {{getDate(detailsData?.entity_meta_data?.filling_date) || "-"}}
                  </span>
                </div>
                <div>
                  <span class="text_heading">Case Status :</span>
                  <span class="text_value ml-2 mr-4">
                    {{detailsData?.entity_meta_data?.case_status || "-" }}
                  </span>
                </div>
              </div>
              <div class="flex p-0 m-0" v-if="detailsData.entity_meta_data.judges">
                  <div class="mr-3">
                    <span class="text_heading">Judges :</span>
                    <span class="text_value ml-2 mr-4">{{  detailsData?.entity_meta_data?.judges.map(i => `${i.name}`).join(',') }}</span>
                  </div>
                  <div class="mr-3">
                    <span class="text_heading">Plantiff :</span>
                    <span class="text_value ml-2 mr-4" :title="`${detailsData?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'PLAINTIFF').map(i => i.name).join(',')}`"
                    >{{ detailsData?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'PLAINTIFF').map(i => i.name).join(" ").substr(0,20)+'...' }}</span>
                  </div>
                  <div>
                    <span class="text_heading">Defendant :</span>
                    <span class="text_value ml-2 mr-4" :title="`${ detailsData?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'DEFENDANT').map(i => i.name).join(',') }`"
                    >{{ detailsData?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'DEFENDANT').map(i => i.name).join(" ").substr(0,20)+'...'  }}</span>
                  </div>
              </div>
              <div class="flex flex-wrap items-center">
                <div v-for="(itm, indx) in getRiskCategories(detailsData)"
                  :key="indx"
                  class="label mr-3 my-2 rounded-full"
                  :class="checkCategories(itm)"
                >
                  <span>{{ itm }}</span>
                </div> 
                <!-- <span
                  v-if="showSeeMore(item)"
                  @click="toggleSeeMore"
                  class="f-title cursor-pointer pl-2"
                >
                  {{ this.seeMore ? "Show Less" : "Load More" }}
                  </span> -->
              </div>
              <p v-show='detailsData.source_name ==	"judyrecords"' :title="detailsData?.snippet">{{detailsData.source_name ==	"judyrecords" ? detailsData?.snippet : ''}}<p/>
      </div>
      <div class="print-d-block  flex  flex-col  space-y-4  overflow-y-auto  px-2  overflow-x-hidden" v-show="partyDetails.length || partyDetails.length || judgyDetails.length">
        <div class="print-hidden text-blue-600  ml-auto  cursor-pointer  inline-flex">
          <div @click="handleExpandAll" v-if="checkExpand">Expand All</div>
          <div @click="handleCollapseAll" v-else>Collapse All</div>
        </div>
      </div>
        <template v-if="docket.length">
          <div @click="expandedDetails.Details = !expandedDetails.Details"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">Docket Entries</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{'rotate-180': !expandedDetails.Details}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="flex flex-col border-t-0" v-if="expandedDetails.Details">
            <div class="overflow-x-auto scroll">
                <div class="align-middle inline-block min-w-full">
                    <div class="shadow overflow-hidden rounded border border-solid border-gray-300">
                               <table id="industryCodesTable"
                                   class="industryCodesTable w-full divide-y divide-gray-200">
                                   <thead class="bg-gray-50">
                                       <tr>
                                           <th scope="col"
                                               class=" w-24 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                               Date
                                           </th>
                                           <th scope="col"
                                               class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                               DOCKET ENTRIES
                                           </th>
                                       </tr>
                                   </thead>
                                   <tbody  >
                                       <!-- Odd row -->
                                       <tr v-for="item in docket"  :key="item?.docket" class="border-b-2">
                                           <td class="px-6 py-2  text-sm text-gray-500 text-bold w-32" >{{item?.docket_entry_date  }}</td>
                                           <td class="px-6 py-2 text-sm text-gray-500 text-bold" >{{ item?.text || item?.description }}</td>
                                       </tr>
                                       <!-- <tr > 
                                          <td class="justify-center item-center"><Loader/></td>
                                       </tr> -->
                                   </tbody>
                               </table>
                    </div>                     
               </div>         
            </div>  
          </div>
        </template>
        <template v-else >
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">Docket Entity</div>
          </div>
        </template>
        <template v-if="partyDetails.length">
          <div @click="expandedDetails.PartyInfo = !expandedDetails.PartyInfo"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">Party Info</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{'rotate-180': !expandedDetails.PartyInfo}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="flex flex-col border-t-0" v-if="expandedDetails.PartyInfo">
            <div class="overflow-x-auto scroll">
                <div class="align-middle inline-block min-w-full">
                    <div class="shadow overflow-hidden rounded border border-solid border-gray-300">
                               <table id="industryCodesTable"
                                   class="industryCodesTable w-full divide-y divide-gray-200">
                                   <thead class="bg-gray-50">
                                       <tr>
                                           <th scope="col"
                                               class=" w-24 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                              Party Name
                                           </th>
                                           <th scope="col"
                                               class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                               Type
                                           </th>
                                       </tr>
                                   </thead>
                                   <tbody  >
                                       <!-- Odd row -->
                                       <tr v-for="item in partyDetails" :key="item?.id" class="border-b-2">
                                           <td class="px-6 py-2  text-sm text-gray-500 text-bold w-80" >{{ item?.first_name ||item.name}}</td>
                                           <td class="px-6 py-2 text-sm text-gray-500 text-bold" >{{ item?.source_party_role || item.party_role  }}</td>
                                       </tr>
                                   </tbody>
                               </table>
                           </div>                     
               </div>         
            </div>  
          </div>
        </template>
        <template v-else >
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">Party Info</div>
          </div>
        </template>
        <template v-if="judgyDetails.length">
          <div @click="expandedDetails.judgyDetails = !expandedDetails.judgyDetails"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">Details</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{'rotate-180':!expandedDetails.judgyDetails}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="flex flex-col border-t-0 " v-if="expandedDetails.judgyDetails">
             <div v-html="judgyDetails[0].page"></div>
          </div>
        </template>
    </div>
</template>

<script>
import {DateTime} from "luxon";
// import Loader from "@/components/loader";  
 export default {
    components:{
      // Loader,
    },
    props: {
        identifiers: Array,
        detailsData: Object,
        confidence: Boolean,
        docket:Array,
        attroney:Array,
        partyDetails:Array,
        judgyDetails:Array,
  },
  data(){
    return {
        expandedDetails:{
            Details:true,
            PartyInfo:true,
            judgyDetails:true,
        },
        expandAll: true,

    }
  },
  methods:{
    checkCategories(item) {
      let risk = this.getOptions?.find((v) => v.category == item);
      if (risk) {
        return risk.category_type === "Risk Categories"
          ? item.entity_status === "Amber"
            ? "amberLabel"
            : "redLabel"
          : "greenLabel";
      } else {
        return "redLabel";
      }
    },
    handleExpandAll() {
      this.expandedDetails.PartyInfo = true;
      this.expandedDetails.Details = true;
      this.expandedDetails.judgyDetails = true;
      this.expandAll = false;
    },
    handleCollapseAll() {
      this.expandedDetails.PartyInfo = false;
      this.expandedDetails.Details = false;
      this.expandedDetails.judgyDetails = false;
      this.expandAll = true;
    },
    getRiskCategories(item) {
      const categories = item.risk_categories || [];
      return categories
    },
    getDate(date){
      return  DateTime.fromJSDate(new Date(date)).toFormat('dd-MM-yyyy');
    }
  },
  computed:{
    checkExpand(){
      if(this.expandedDetails.Details && this.expandedDetails.PartyInfo && this.expandedDetails.judgyDetails){
        return false;
      }
      else {
        return true;
      }
    }
  }

};

</script>

<style scoped lang="scss">
@import "../../../assets/scss/color.scss";

.accordian_color {
  background-color: $lightray_accordian !important;
  border: 1px solid $lightray_accordian !important;
}
.custom_tr{
    padding: 0 !important;
}
.custom_scroll::-webkit-scrollbar {
  display: none !important;
}

.text_heading {
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.text_value {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.label-box {
  display: flex;
  width: 25px;
  height: 24px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}


.label {
  color: var(--complete, #67b52b);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.redLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--light-red, #ffebeb) !important;
  color: var(--red, #eb3131) !important;
}

.amberLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: rgba(235, 155, 0, 0.2) !important;
  color: rgb(235, 155, 0) !important;
}

.greenLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(5, 159, 39, 0.08) !important;
  color: var(--green, #42b85c) !important;
}

</style>