<!-- Modal.vue -->
<template>
    <div class="detail_modal" >
        <div class="modal-content relative">
            <div class="w-full flex justify-end absolute top-2 right-2 text-grey-400">
                <button @click="closeModal">
                    <svg width="15" height="15" viewBox="0 0 10 9" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.00049 3.55767L8.50653 0.257812L9.50826 1.20062L6.00222 4.50047L9.50826 7.80027L8.50653 8.74306L5.00049 5.44327L1.49441 8.74306L0.492676 7.80027L3.99877 4.50047L0.492676 1.20062L1.49441 0.257812L5.00049 3.55767Z" fill="black"/>
                    </svg>
                </button>
            </div>
            <div class="pt-2">
                <!-- modal content will come here -->
                <slot></slot>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    mounted(){
       
    },
    props:{

    },
    methods:{
        closeModal(){
            this.$emit('closeModal')
        },
    }
  };
  </script>
  
  <style scoped>
  /* Add your modal styles here */
  
  .detail_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 50%;
  }
  </style>
  