import { get } from "lodash";
import { DateTime } from "luxon";
import printDataMixin from "@shared/mixins/printDataMixin";
export default {
    components: {

    },
    data() {
        return {
            isPrintLoader: false,
            matching:Array,
        }
    },
    props: {
        identifiers: Array,
        detailsData: Object,
        confidence: Boolean,
    },
    mounted() {
       
    },
    updated() {
        const news = this.$refs.news;
        if (news) {
            const elements = news.querySelectorAll("[data-attribute]");
            if(elements.length){
            elements.forEach((element) => {
              const innerHtml = element.innerHTML;
              let attributeValue = element.getAttribute("data-attribute");
              if(attributeValue.length){
              attributeValue =  attributeValue.split(",");
              let highlightedHtml = innerHtml;
              attributeValue.forEach((att) => {
                highlightedHtml = this.highlightText(highlightedHtml, att);
              });
              element.innerHTML = highlightedHtml;
            }
            });
          }
        }
    },
    created() {
        this.matching = this.identifiers.map(i => i.entity);
    },
    mixins: [printDataMixin],
    methods: {
        highlightText(text, query) {
            const regex = new RegExp(`(${query})`, "gi");
            return text.replace(regex, (match) => {
              return `<span class='highlighted badge badge-warning'>${match}</span>`;
            });
        },
        getObject(object, property, defaultValue) {
            return get(object, property, defaultValue);
        },
        getContent(content) {
            if (!content) {
                return " ";
            }
            let regex = /(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g
            let contentHTML = ''
            contentHTML = content
            return contentHTML.replace(regex, " ")
        },
        getFormattedTime(ts, format) {
            let date = DateTime.fromISO(ts);
            return format ? date.toFormat(format) : date.toFormat("EEE, dd MMM yyyy, hh:mm:ss a");
        },
        async printDocuments(id, fileName, key, noPrint) {
            this.isPrintLoader = true;
            let htmlPDF = "";
            htmlPDF = await this.printPage(id, fileName, noPrint)
            return htmlPDF;
        },
        async printPage(id, name, print, noPrint) {
            this.printDone = false;
            this.isPrintLoader = true;
            if (!print) {
                let htmlPDF = await this.printData(id, name, noPrint);
                this.$emit("updatePrintStatus", "");
                this.isPrintLoader = false;
                if (noPrint) {
                    return htmlPDF;
                }
            }
        },
    }
};  
