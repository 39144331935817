// eslint-disable

const KlGraph = () => import("./osint-graph/KlGraph.vue");
export default {
  components: {
    KlGraph,
  },
  props: {
    graphData: Array,
    maxGraphDepth: Number,
    childComponent: Boolean,
    caseId:String,
    mainEntity:Array,
    graphLoading: Boolean,
    loaderProgress: Number,
    selectedFilters:Object,
  },
  computed: {
    getGraphData(){
      if(this.graphData){
        return this.graphData;
      }
    },
    getGraphLoading(){
      return this.graphLoading
    },
    getFilterdata(){
      return this.selectedFilters
    }
  },
  methods: {
    graphUpdate(graphData){
      this.$emit("graphUpdate", graphData);
    },
  },
};