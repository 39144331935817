<template>
<div>  
 <div v-if="this.companyDetails?.entity_meta_data?.company_detail">
    <div id="print-section" ref="news">
    <div class="text-xl items-center">
        <div class="flex flex-row justify-between">
            <div class="flex">
                <div>{{ companyDetails?.entity }}</div>
                <div class="flex text-xl items-center ml-3">
                    <span class="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {{ getCompany?.dunsControlStatus?.operatingStatus?.description }}
                    </span>
                </div>
            </div>  
            <span v-if="showPrintButton" id="print-button" @click="printDocuments('print-section','companyData')"
                class="print-hidden inline-flex  cursor-pointer hover:text-brand-hover">
                <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 capitalize"
                    v-tippy content="Print">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-printer-fill" viewBox="0 0 16 16">
                        <path
                            d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                        <path
                            d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                    </svg>
                    <!-- <div class="mr-2">Print</div> -->
                    <!-- <neo-loader class="" :loading="isPrintLoader" /> -->
                </span>
            </span>
        </div>
        <span class="text-gray-600 text-sm">
                {{ 
                   getCompany?.primaryAddress?.streetAddress.line1 +", " +
                   getCompany?.primaryAddress?.addressLocality.name + ", "+
                   getCompany?.primaryAddress?.addressCountry.name +" "+
                   getCompany?.primaryAddress?.postalCode
                }}
        </span>
        <div class="text-gray-400 text-sm mt-1">
            <div class="mt-1 text-sm text-gray-800 flex flex-wrap">
                <div class="text-gray-400 mr-2 w-2/5">Former Primary Name :</div>
                <div>{{ getCompany?.formerPrimaryNames[0]?.name  || 'Not available'}} </div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex flex-wrap">
                <div class="text-gray-400 mr-2 w-2/5">Former Registered Name :</div>
                <div>{{ getCompany?.formerRegisteredNames[0]?.name  || 'Not available'}} </div>
            </div>
            <div class="mt-1 text-sm flex flex-wrap">
                <div class="w-2/5 mr-2">Registry :</div>
                <a target="_blank" rel="noreferrer" class="no-underline "> 
                    {{ getCompany?.registrationNumbers?.typeDescription  || 'Not available'}}  
                </a>
            </div>
            <div class="text-sm text-gray-800 flex mt-1 flex-wrap">
                <div class="text-gray-400 mr-2 w-2/5">Company Number :</div>
                <div>{{ companyDetails?.entity_meta_data?.registrationNumbers || 'Not available'}}</div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex flex-wrap">
                <div class="text-gray-400 mr-2 w-2/5">Company Type :</div>
                <div>{{ getCompany?.businessEntityType?.description || 'Not available'}} </div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex flex-wrap">
                <div class="text-gray-400 mr-2 w-2/5">More Senior Contact  :</div>
                <div>{{ companyDetails?.entity_meta_data?.mostSeniorPrincipals[0]?.fullName || 'Not available'}} </div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Jurisdiction :</div>
                <div>{{ getJurdictionCode(getCompany?.countryISOAlpha2Code) || 'Not available' }}  </div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Incorporation Date :</div>
                <div >{{ getCompany?.incorporatedDate || 'Not available' }}</div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Last Updated Date :</div>
                <div> Not available </div>
            </div>
        </div>
    </div>

    <div class="print-d-block  flex  flex-col  space-y-4  overflow-y-auto  px-2  overflow-x-hidden">
        <div class="print-hidden  text-blue-600  ml-auto  cursor-pointer hover: text-blue-400  inline-flex">
            <div @click="handleExpandAll" v-if="checkExpand">Expand All</div>
            <div @click="handleCollapseAll" v-else>Collapse All</div>
        </div>
    </div>
    <!--Details-->
    <div :data-attribute="this.matching">
        <div @click="expandedDetails.Details = !expandedDetails.Details"
            class=" flex items-center py-1 px-2 accordian_color mt-4  justify-between border border-solid  rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">Details</div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                    :class="{'rotate-180': expandedDetails.Details}" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
        </div>
        <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.Details">
            <!-- <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Source :</div>
                <a class="text-blue-500 no-underline" :href="companyDetails?.company?.source?.url" target="_blank" rel="noreferrer">{{companyDetails?.source_name || 'Not available'}}</a>
            </div> -->
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Registry URL :</div>
                <a v-if="companyDetails?.company?.registry_url" class="text-blue-500 no-underline break-all"
                    :href="companyDetails?.company?.registry_url" target="_blank" rel="noreferrer">{{
                    companyDetails.company.registry_url || 'Not available' }}</a>
                 <p v-else class="text-gray-400 text-sm">  Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Branch :</div>
                <p class="text-gray-400 text-sm">  Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Agent Name :</div>
                <p class="text-gray-400 text-sm">  Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Agent Address :</div>
                <p class="text-gray-400 text-sm">  Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Is Fortune1000 Listed :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.isFortune1000Listed || 'Not available' }}</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Is Forbes Largest Private Companies Listed :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.isForbesLargestPrivateCompaniesListed || 'Not available'}} </p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Is Non Classified Establishment :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.isNonClassifiedEstablishment || 'Not available'}} </p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Employee Reliability :</div>
                <p  v-if="getCompany?.assignmentModel?.employeeReliability?.description "> {{getCompany?.assignmentModel?.employeeReliability?.description || 'Not available'}}</p> <span v-else>Not available</span>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Sales Reliability :</div>
                <p  v-if="getCompany?.assignmentModel?.salesReliability?.description"> {{getCompany?.assignmentModel?.salesReliability?.description }}</p> <span v-else>Not available</span>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Has Change In Global Ultimate :</div>
                <p class="text-gray-400 text-sm">{{ getCompany?.assignmentModel?.hasChangeInGlobalUltimate || 'Not available'}} </p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Domestic Family Tree Members Count :</div>
                <p class="text-gray-400 text-sm">{{ getCompany?.assignmentModel?.domesticUltimate?.familyTreeMembersCount || 'Not available'}}</p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Global Family Tree Members Count :</div>
                <p class="text-gray-400 text-sm">{{ getCompany?.assignmentModel?.globalUltimate?.familyTreeMembersCount || 'Not available'}}</p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Domestic Industry Sectors Count :</div>
                <p class="text-gray-400 text-sm"> {{ getCompany?.assignmentModel?.domesticUltimate?.industrySectorsCount || 'Not available'}}</p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Global Industry Sectors Count :</div>
                <p class="text-gray-400 text-sm"> {{ getCompany?.assignmentModel?.globalUltimate?.industrySectorsCount || 'Not available'}}</p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Domestic Primary Sector :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.assignmentModel?.domesticUltimate?.primarySector?.industryDescription || 'Not available'}} </p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Global Primary Sector :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.assignmentModel?.globalUltimate?.primarySector?.industryDescription || 'Not available' }} </p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Domestic Secondary Sector :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.assignmentModel?.domesticUltimate?.secondarySector?.typeDescription || 'Not available'}} </p>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Global Secondary Sector :</div>
                <p class="text-gray-400 text-sm" >{{ getCompany?.assignmentModel?.globalUltimate?.secondarySector?.typeDescription || 'Not available'}} </p>
            </div>

            <div class="mt-2">
                <div class="text-gray-400 font-medium">Industry codes:</div>
                <div class="flex flex-col mt-1">
                    <div class="overflow-x-auto scroll">
                        <div class="align-middle inline-block min-w-full">
                            <div class="shadow overflow-hidden rounded border border-solid border-gray-300">
                                <table id="industryCodesTable"
                                    class="industryCodesTable min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider w-2/6">
                                                Code
                                            </th>
                                            <th scope="col"
                                                class="px-16 py-3 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                                                Description
                                            </th>
                                            <!-- <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                                                Description
                                            </th> -->
                                        </tr>
                                    </thead>
                                    <tbody v-if="true">
                                        <!-- Odd row -->
                                        <tr v-for="ic,k in getCompany?.industryCodes" :key="k">
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800 w-2/6">{{
                                                ic?.code || 'Not available'}}</td>
                                            <td class="px-16 py-2 whitespace-nowrap text-sm text-gray-800">{{
                                                ic.description || 'Not available'}}</td>
                                            <!-- <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{
                                                ic.typeDescription || 'Not available'}}</td> -->
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center"
                                                colspan="3">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="text-gray-400 font-medium">Employee Count and Sales:</div>
                <div class="flex flex-col mt-1">
                    <div class="overflow-x-auto scroll">
                        <div class="align-middle inline-block min-w-full">
                            <div class="shadow overflow-hidden rounded border border-solid border-gray-300">
                                <table id="industryCodesTable"
                                    class="industryCodesTable min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                                                Company Type
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                                                Employee Count
                                            </th>
                                            <th scope="col"
                                                class="px-6 py-3 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                                                Sales amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="true">
                                        <!-- Odd row -->
                                        <tr >
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{"Physical Location" }}</td>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{getCompany?.assignmentModel?.physicalLocation?.employeeCount || 'Not available'}}</td>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{getCompany?.assignmentModel?.physicalLocation?.salesAmount || 'Not available'}}</td>
                                        </tr>
                                        <tr >
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{"Linked Companies" }}</td>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{getCompany?.assignmentModel?.linkedCompanies?.employeeCount || 'Not available'}}</td>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{getCompany?.assignmentModel?.linkedCompanies?.salesAmount || 'Not available' }}</td>
                                        </tr>
                                        <tr >
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{"Country Group" }}</td>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{getCompany?.assignmentModel?.countryGroup?.employeeCount || 'Not available'}}</td>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-800">{{getCompany?.assignmentModel?.countryGroup?.salesAmount  || 'Not available'}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center"
                                                colspan="3">
                                                No data available
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--Financial details-->
    <div :data-attribute="this.matching">
        <div @click="expandedDetails.financialDetails = !expandedDetails.financialDetails"
            class=" flex items-center py-1 px-2 accordian_color mt-4  justify-between border border-solid  rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">Financial Strength</div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                    :class="{'rotate-180': expandedDetails.financialDetails}" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                </svg>
            </div>
        </div>
        <div class="flex text-xl items-center pt-3" v-if="expandedDetails.financialDetails">
            <span v-if="getFinancial?.isHighRiskBusiness" class="px-3 py-1 text-xs font-medium bg-red-100 text-red-400 rounded mr-2">
               High Risk Business
            </span>
            <span v-if="getFinancial?.isDeterioratingBusiness" class="px-3 py-1 text-xs font-medium bg-red-100 text-red-400 rounded mr-2">
                Deteriorating Business
            </span>
            <span v-if="getFinancial?.dnbAssessment?.hasSevereNegativeEvents" class="px-3 py-1 text-xs font-medium bg-red-100 text-red-400 rounded mr-2">
                Severe Negative Events
            </span>
        </div>
        <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.financialDetails">
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Risk :</div>
                <p v-if="getFinancial?.dnbAssessment?.failureScore?.classScoreDescription" >{{
                    getFinancial?.dnbAssessment?.failureScore?.classScoreDescription || 'Not available' }}</p>
                <p v-else> Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Risk Date:</div>
                <p v-if="getFinancial?.dnbAssessment?.failureScore?.scoreDate" >{{
                    getFinancial?.dnbAssessment?.failureScore?.scoreDate || 'Not available'}}</p>
                <p v-else> Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Delinquency :</div>
                <p  v-if=" getFinancial?.dnbAssessment?.delinquencyScore?.classScoreDescription"> {{
                    getFinancial?.dnbAssessment?.delinquencyScore?.classScoreDescription|| 'Not available' }}</p> 
                <p v-else> Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Delinquency Date :</div>
                <p  v-if="getFinancial?.dnbAssessment?.delinquencyScore?.scoreDate"> {{
                    getFinancial?.dnbAssessment?.delinquencyScore?.scoreDate || 'Not available'}}</p> 
                <p v-else> Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Adverse History :</div>
                <p  v-if="getFinancial?.dnbAssessment?.historyRating?.description"> {{
                    getFinancial?.dnbAssessment?.historyRating?.description || 'Not available' }}</p> 
                <p v-else> Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Financial Strength :</div>
                <p  v-if="getFinancial?.dnbAssessment?.standardRating?.financialStrength"> {{ nordicRating[getFinancial?.dnbAssessment?.standardRating?.financialStrength]  || 'Not available'}}</p>
                <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Financial Description :</div>
                <div class="w-80"> 
                    <span  v-for="raiting,index in getFinancial?.dnbAssessment?.standardRating?.ratingReason" :key="index"> {{ raiting?.description + ', ' }}</span> 
                </div>
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Creditworthiness :</div>
                <p  v-if="getFinancial?.dnbAssessment?.standardRating?.riskSegment"> {{
                    Creditworthiness[getFinancial?.dnbAssessment?.standardRating?.riskSegment-1] || 'Not available'}}</p> 
                    <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Financial Strength as of :</div>
                <p  v-if="getFinancial?.dnbAssessment?.standardRating?.scoreDate"> {{
                    getFinancial?.dnbAssessment?.standardRating?.scoreDate || 'Not available'}}</p> 
                    <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex gap-2 items-center flex-wrap w-2/5">
                    Nordic Rating :
                    <Iicon @click="openModal('nordic')"/>
                </div>
                <p  v-if="getFinancial?.dnbAssessment?.standardRating?.financialStrength">{{ getFinancial?.dnbAssessment?.standardRating?.financialStrength || 'Not available'}}</p> 
                <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Nordic Rating Date :</div>
                <p v-if="getFinancial?.dnbAssessment?.standardRating?.scoreDate"> {{ getFinancial?.dnbAssessment?.standardRating?.scoreDate || 'Not available'}}
                </p> <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex gap-2 items-center flex-wrap w-2/5">
                    Max recommended credit amount :
                    <Iicon @click="openModal('assess')"/>
                </div>
                <p  v-if="getFinancial?.dnbAssessment?.creditLimitRecommendation?.maximumRecommendedLimit?.value || getFinancial?.dnbAssessment?.creditLimitRecommendation?.maximumRecommendedLimit?.currency "> {{
                    getFinancial?.dnbAssessment?.creditLimitRecommendation?.maximumRecommendedLimit?.value +" "+ getFinancial?.dnbAssessment?.creditLimitRecommendation?.maximumRecommendedLimit?.currency }}</p> 
                <p v-else> Not available</p>         
               </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex gap-2 items-center flex-wrap w-2/5">
                    Assessment date of credit limitation :
                </div>
                <p  v-if="getFinancial?.dnbAssessment?.creditLimitRecommendation?.assessmentDate "> {{
                    getFinancial?.dnbAssessment?.creditLimitRecommendation?.assessmentDate }}</p> 
                <p v-else> Not available</p>     
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Average recommended credit amount :</div>
                <p  v-if="getFinancial?.dnbAssessment?.creditLimitRecommendation?.averageRecommendedLimit?.value || getFinancial?.dnbAssessment?.creditLimitRecommendation?.averageRecommendedLimit?.currency"> {{
                    getFinancial?.dnbAssessment?.creditLimitRecommendation?.averageRecommendedLimit?.value +" "+ getFinancial?.dnbAssessment?.creditLimitRecommendation?.averageRecommendedLimit?.currency  }}</p> 
                <p v-else> Not available</p> 
               </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5 flex gap-2 items-center">
                    Assessment date of average credit limitation :
                    <Iicon @click="openModal('avg')"/>
                </div>
                <p  v-if="getFinancial?.dnbAssessment?.creditLimitRecommendation?.averageRecommendedLimit?.assessmentDate"> {{
                    getFinancial?.dnbAssessment?.creditLimitRecommendation?.averageRecommendedLimit?.assessmentDate }}</p> <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex gap-2 items-center flex-wrap w-2/5">
                    Emerging market Mediation Score :
                    <Iicon @click="openModal('eme')"/>
                </div>
                <p  v-if="getFinancial?.dnbAssessment?.emergingMarketMediationScore?.classScore"> {{
                    getFinancial?.dnbAssessment?.emergingMarketMediationScore?.classScore }}</p> <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Emerging market Mediation Score Date :</div>
                <p  v-if="getFinancial?.dnbAssessment?.emergingMarketMediationScore?.scoreDate"> {{
                    getFinancial?.dnbAssessment?.emergingMarketMediationScore?.scoreDate }}</p> <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex gap-2 items-center flex-wrap w-2/5">
                    Probability of large layoffs in next 6 months :
                   
                </div>
                <p  v-if="getFinancial?.dnbAssessment?.failureScore?.nationalPercentile || getFinancial?.dnbAssessment?.failureScore?.classScoreDescription"> {{
                    `${getFinancial?.dnbAssessment?.failureScore?.nationalPercentile + getFinancial?.dnbAssessment?.failureScore?.classScoreDescription}%` }}</p> <p v-else> Not available</p> 
            </div>
            <!-- <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">Emerging market Mediation Score Date :</div>
                <p  v-if="companyDetails?.company?.agent_name"> {{
                    "companyDetails.company.agent_name" }}</p> <span v-else>Not available</span>
            </div> -->
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex gap-2 items-center flex-wrap w-2/5">
                    TSR Rating :
                    <Iicon @click="openModal('tsr')"/>
                </div>
                <p  v-if="getFinancial?.tsrRating?.rating"> {{
                    getFinancial?.tsrRating?.rating }}</p> <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">TSR Rating Date :</div>
                <p  v-if="getFinancial?.tsrRating?.ratingDate"> {{
                    getFinancial?.tsrRating?.ratingDate }}</p> <p v-else> Not available</p> 
            </div>
            <div class="mt-1 text-sm text-gray-800 flex">
                <div class="text-gray-400 mr-2 flex-wrap w-2/5">TSR Rating Range :</div>
                <p  v-if="getFinancial?.tsrRating?.ratingRange"> {{
                    getFinancial?.tsrRating?.ratingRange }}</p> 
                <p v-else> Not available</p>    
            </div>
        </div>
    </div>
    </div>
    <!-- Details Modal -->
    <Modal v-if="showModal" @closeModal="closeModal">
      <!-- Modal content goes here -->
      <div class="break-words" v-html="moadalData"></div>
    </Modal>
 </div>
 <div v-else class="flex justify-center items-center">
    No Data found
 </div>
</div>    
</template>
<script>
import printDataMixin from "@shared/mixins/printDataMixin";
// import { getOwnershiDetails } from "../../../pages/search-records/services";
import Iicon from "../../../assets/svg/i-icon.svg";
import Modal from "./dnbModal";

export default {
    name: "company-details",
    components:{
        Iicon,
        Modal,
    },
    data: function() {
        return {
            expandedDetails: {
                Details: true,
                officer: true,
                filings: true,
                ownership: true,
                financialDetails: true,
                graph: true,
                previousName:true
            },
            expandAll: true,
            company: {},
            matching: [],
            showModal: false,
            moadalData: '',
            nordicRating: {
                "5A" : "$50,000,00 to and over" ,
                "4A" : "10,000,000 to 49,999,999", 
                "3A" : "1,000,00 to 9,999,999", 
                "2A" : "750,000 to  999,999",
                "1A" : "500,000 to 749,999" ,
                "BA" : "300,000 to 499,999",
                "BB" : "200,000 to 299,999" ,
                "CB" : "125,000 to 199,999",
                "CC" : "75,000 to 124,999", 
                "DC" : "50,000 to 74,999",
                "DD" : "35,000 to 49,999" ,
                "EE" : "20,000 to 34,999",
                "EF" : "10,000 to and 19,999" ,
                "GG" : "5,000 to 9,999",
                "HH" : "up to and 4,999", 
            },
            Creditworthiness: ["High","Good","Fair","Limited"],
        }
    },
    props: {
        companyDetails: Object,
        jurisdictionData: Array,
        ownership: Array,
        showPrintButton: Boolean,
        identifiers: Array,
    },
    mixins: [printDataMixin],
    computed:{
        checkExpand(){
            if(this.expandedDetails.Details && this.expandedDetails.officer && this.expandedDetails.filings&& this.expandedDetails.ownership&&this.expandedDetails.financialDetails && this.expandedDetails.graph && this.expandedDetails.previousName){
              return false;
            }
            else {
              return true;
            }
          },
        getFinancial(){
            return this.companyDetails?.entity_meta_data?.financial_detail?.organization
        },
        getCompany(){
            return this.companyDetails?.entity_meta_data?.company_detail?.organization
        } 
    },
    mounted(){
        // this.handleCompanydetails();
    },
    methods: {
      getJurdictionCode(code){
        let SelectedCompany = localStorage.getItem('SelectedCompany')
        SelectedCompany = JSON.parse(SelectedCompany);
         if(SelectedCompany?.jurisdictionData.length){
            let jur = SelectedCompany?.jurisdictionData.find((j)=> j.value = code.toLowerCase())
            return jur.label
         }
         return 'Not Available'
      }, 
      openModal(s) {
        this.showModal = true;
        if(s == 'tsr'){
            this.moadalData = '<p>The Tokyo Shoko Research (TSR) Total Raw Score is a numeric value between -25 and 100 that is manually assigned by a TSR investigator through a company analysis points system which is based on management ability, growth potential, stability and disclosure / reputation. A high score means that a company is considered highly regarded, whereas a low score means that a company is not highly regarded.</p>'
        }
        else if(s == 'eme' ){
            this.moadalData = '<p>A numeric value from 1 to 10 (where 1 represents a lower risk and 10 a higher risk), that succinctly expresses the likelihood of risk by specifying in which risk group this organization belongs. In other words, it specifies where this organization falls in a statistical distribution of all businesses (e.g., a bell curve, a half-normal distribution).</p>'
        }
        else if(s == 'assess'){
            this.moadalData = '<p>The greatest amount of credit that should be extended to this entity.</p>'
        }else if(s == 'avg'){
            this.moadalData = '<p>the average amount of credit that should be extended to this entity.</p>'
        }else if(s == 'nordic'){
            this.moadalData = `
                                <p class="text-xl text-stone-200">Textual description that provides an indication of the strength of the organization to cope with adverse trading periods or exploit investment opportunities.<p><br>
                        <p class="text-xl text-stone-200"> 1. AAA- Highest Credit worthiness </p>
                        <p class="text-gray-400"> A company with an exceedingly strong abillity to meet current payment obligations.</p>
                        <br>
                        <p class="text-xl text-stone-200"> 2. AA - Good Creditworthiness </p>
                        <p class="text-gray-400">A company with a good ability to meet current payment obligations.</p>
                        <br>
                        <p class="text-xl text-stone-200"> 3. A - Creditworthy </p>
                        <p class="text-gray-400">A company with sufficient ability to meet current payment obligations.</p>
                        <br>
                        <p class="text-xl text-stone-200"> 4. NY - Newly started companies </p>
                        <p class="text-gray-400">Newly established company. There is no negative information
                            regarding the company's solvency. The company's further progress
                            should be closely monitored.</p>
                        <br>
                        <p class="text-xl text-stone-200"> 5. B - Credit against security </p>
                        <p class="text-gray-400">Credit risk. The company's ability to meet current payment obligations
                            is estimated weak.</p>
                        <br>
                        <p class="text-xl text-stone-200"> 6. C-Credit advised against </p>
                        <p class="text-gray-400">High credit risk. The company's ability to meet current payment
                            obligations is estimated to be very poor.</p>
                        <br>
                        <p class="text-xl text-stone-200"> 7. IR - Cannot be rated </p>
                        <p class="text-gray-400">Significant information regarding the company is missing or outdated.
                            Banks, insurance companies, Investment companies etc, also receive
                            this rating.</p>
                             `
        }
      },
      closeModal() {
        this.showModal = false;
      },
        // highlightText(text, query) {
        //     const regex = new RegExp(`(${query})`, "gi");
        //     return text.replace(regex, (match) => {
        //       return `<span class='highlighted badge badge-warning'>${match}</span>`;
        //     });
        // },
        async handleCompanydetails(){
            // const response = this.companyDetails;
            // // const { data: chain_data } = await osintAxios.post("/api/v1/company/search/chain", {
            // //     comp_id: this.companyDetails.company.company_number,
            // // })
            // let payload={comp_id: this.companyDetails?.company?.company_number}
            // const { data: chain_data } = await getOwnershiDetails(payload)
            // this.ownership=chain_data.entity
            // let {company } = this;
            // company.modalCompanyDetailsData = response;
            // if (company?.modalCompanyDetailsData?.company) {
            //     company.modalCompanyDetailsData.company.chain_data = chain_data;
            //     const nodes = company.modalCompanyDetailsData.company?.chain_data?.entity?.map((ele) => {
            //         let node = {
            //             type: "node",
            //             id: ele._id,
            //             t: ele.name,
            //             // u: require(`@/assets/lightray/icons/flags/${ele.jurisdiction[0].code.split("_")[0].toLowerCase()}.svg`),
            //         };
            //         if (ele.main_node) {
            //             node.ha0 = {
            //                 c: "#0d69d5", // the halo fill colour
            //                 r: 100, // the halo radius
            //                 w: 5, // the halo width
            //             };
            //         }
            //         node.u = require(`../../../../assets/lightray_new/icons/graph/${ele.name_type == "Company" ? "company_icon.png" : "user.png"}`);
            //         if (ele?.jurisdiction?.length > 0 && ele?.jurisdiction[0]?.code) {
            //             node.g = [
            //                 {
            //                     u: require(`../../../../assets/lightray_new/icons/flags/${ele.jurisdiction[0].code.split("_")[0].toLowerCase()}.png`),
            //                     p: 305,
            //                     r: 40,
            //                 },
            //             ];
            //         }
            //         return node;
            //     });
            //     const edges = company.modalCompanyDetailsData?.company?.chain_data?.relations?.map((ele, i) => ({ type: "link", id: `${i + 1}_edge`, id1: ele.from, id2: ele.to, w: ele.active ? 2 : 1, a2: ele.from, c: ele.active ? "#000000" : "#AAAAAA" }));
            //     const graph_data = {
            //         type: "LinkChart",
            //         items: [...nodes, ...edges],
            //     };
            //     company.modalCompanyDetailsData.company.graph_data = graph_data;

            // }
        },
        // getJurisdictionLabel(code) {
        //     // let data = this.jurisdictionData.filter(res=> res.value == code)
        //     // return data[0].label
        // },
        handleExpandAll() {
            this.expandedDetails.Details = true;
            this.expandedDetails.financialDetails = true;
            this.expandAll = false;
        },
        handleCollapseAll() {
            this.expandedDetails.Details = false;
            this.expandedDetails.financialDetails = false;
            this.expandAll = true  
        },
        async printDocuments(id, fileName, ) {
            this.handleExpandAll();
            this.isPrintLoader = true;
            let htmlPDF = "";
            setTimeout(async()=>{
                htmlPDF = await this.printPage(id, fileName, )
                this.handleCollapseAll();
                return htmlPDF;
            },450)
          },
          async printPage(id, name, print, ) {
            this.printDone = false;
            this.isPrintLoader = true;
            if (!print) {
              let htmlPDF = await this.printData(id, name, );
              this.$emit("updatePrintStatus", "");
              this.isPrintLoader = false;
                return htmlPDF;
            }
          },
        
    },
    updated(){
    //    const news = this.$refs.news;
    //    if (news) {
    //       const elements = news.querySelectorAll("[data-attribute]");
    //       if(elements.length){
    //       elements.forEach((element) => {
    //         const innerHtml = element.innerHTML;
    //         let attributeValue = element.getAttribute("data-attribute");
    //         if(attributeValue.length)
    //         {
    //           attributeValue =  attributeValue.split(",");
    //           let highlightedHtml = innerHtml;
    //           attributeValue.forEach((att) => {
    //           highlightedHtml = this.highlightText(highlightedHtml, att);
    //           });
    //          element.innerHTML = highlightedHtml;
    //         }
    //       });
    //     }
    //   }
   },
   created(){
    // this.matching = this.identifiers?.map(i => i.entity);
   }

}
</script>
<style lang="scss" scoped>
    .accordian_color{
        border: 1px solid linear-gradient(0deg, #DBDDF1, #DBDDF1),linear-gradient(0deg, #EFF7FF, #EFF7FF) !important;
        background: linear-gradient(0deg, #DBDDF1, #DBDDF1),linear-gradient(0deg, #EFF7FF, #EFF7FF) !important;
    }
    .chartView {
        width: 47vw;
    }
</style>