<template>
    <div class="btn-container mt-3 flex">
        <div @click="$emit('onUnGroup')" class=" btn-group " :class="{
        'btn-active': !isGrouped,
      }">Ungrouped</div>
        <div  @click="$emit('onGroup')" class=" btn-group"
        :class="{
        'btn-active': isGrouped,
      }">Grouped</div>
    </div>
</template>
<script>



export default {
   props:{
    isGrouped:{
        type:Boolean,
        default:false
    }
   }
  };
</script>
<style>
.btn-container{
    display: inline-flex;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #FFEBEB;
    padding: 10px;
}
.btn-group{
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    display: flex;

}
.btn-active{
    border-radius: 4px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

</style>