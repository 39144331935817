export const DEFAULT_NODE_TYPES = ["person", "company", "country", "address", "risk record"];

export const DEFAULT_CONNECTION_COUNT = 3;

export const DEFAULT_PAYLOAD = {
    node_type: ["person", "company", "country", "address", "risk"],
    risk_only: false,
};

export const NODE_TYPES = [
    ...DEFAULT_NODE_TYPES,
    "court record",
    "news",
    "internet",
    "unknown",
];

export const COMBINED_TYPES = Object.freeze({
    Person: "person",
    Country: "country",
});

export const NODE_TYPE_FILTER_TO_PAYLOAD_MAP = new Map([
    ["risk record", "risk"],
    ["court record", "court"],
]);

export const COMBINED_NODE_TYPE_TO_FA_ICON_MAP = new Map([
    [COMBINED_TYPES.Person, "fa-users"],
    [COMBINED_TYPES.Country, "fa-map-marked-alt"],
]);

export const LINK_COLOR = {
    CONFIRMED: "#000000",
    DEFAULT: "#989898",
};

export const imageAlignmentDefinitions = {
    "fa-user": { dy: -10, e: 0.9 },
    "fa-users": { dy: 0, e: 0.8 },
    "fa-flag": { dy: 0, e: 0.8 },
    "fa-building": { dy: 0, e: 0.8 },
    "fa-address-card-o": { dy: 0, e: 0.8 },
    "fa-newspaper": { dy: 0, e: 0.8 },
    "fa-triangle-exclamation": { dy: 0, e: 0.8 },
};
