<template>
    <div>
        <div class="">
            <div :class="menuClasses">
                <div class="offcanvas-overlay" v-if="isOpen"></div>
                <div class="offcanvas-content">
                    <slot></slot>
                </div>
            </div>
        </div>
        <!--<offCanvas v-if="showMenu" :isOpen="showMenu" position="right" @click="closeMenu" class="popupFilter">
        <div>
            <nav class="h-16 border-b-2 p-3 w-fit flex justify-between static z-50 navheader">
            <div class="">
                <span class="absolute top-6 left-5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 512 512"><path fill="#910000" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></span>
                <input class="p-2 border-2 rounded-xl input" type="text" placeholder="Search Peoples"/>
            </div>
            <div>
                <div class="flex">
                    <div class="m-1 text-stone-200 cursor-pointer pt-1" v-for="letter in this.alphabet" :key="letter">{{ letter }}</div>
                </div>
                <div class="flex">
                    <div class="m-1 text-stone-200 cursor-pointer mb-3" v-for="digit in this.digits" :key="digit">{{digit}}</div>
                </div>
            </div>
            <div class="mx-2 cursor-pointer float-right pt-2" @click="$emit('closeMenu')">
                <Cross />
            </div>
            </nav>
            <div class="w-fit overflow-x-auto">
            <div v-for="(row, index) in dividedData" :key="index" class="grid-row">
            <div v-for="(item, idx) in row" :key="idx" class="grid-item">
                <! Display your item data here 
                {{ item.key }}
                </div>
             </div>
             <div class="w-max overflow-x-auto">
             <table>
             <thead>
                    <tr class="h-4.5" v-for="(row, index) in dividedData" :key="index">
                        <td class="personName truncate ... " :title="item?.key" v-for="(item, idx) in row" :key="idx"><input class="m-2" type="checkbox" />{{item.key}} </td>
                    </tr>
            </thead>
             </table>
             </div>
            </div>
            <footer class="h-16 p-1 w-full flex justify-between absolute bottom-0 z-50 navheader">
                <div class="flex w-full h-16 justify-end">
                    <button class="bg-transparent text-red-800 font-semibold w-36  border border-red-800 text-white font-bold py-3 px-5 m-2 rounded-md" @click="resetItem()">
                        Reset
                    </button>
                    <button class="bg-red-800 hover:bg-red-400 text-white w-40   font-bold py-3 px-5 m-2 rounded-md" @click="saveClose()">
                        Save
                    </button>
                </div>
            </footer>
            </div>
     </offCanvas>-->
     </div>
</template>
<script>
// import offCanvas from '../../common/off-canvas';
// import Cross from '../../assets/svg/cross.svg';
export default {
    name: "pop-up",
    components :{
        // offCanvas,
        // Cross
    },
    data(){
        return {
            // alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
            // digits: '0123456789'.split(''),
        }
    },
    props :{
        // showMenu: Boolean,
        // moreFilterData: Array,
        // filterData: Array,
         isOpen: Boolean,
        position: String,
    },
    computed: {
        // dividedData() {
        //     const rows = 6;
        //     const itemsPerRow = Math.ceil(this.moreFilterData.length / rows);
        //     const divided = [];
            
        //     for (let i = 0; i < rows; i++) {
        //         divided.push(this.moreFilterData.slice(i * itemsPerRow, (i+1) * itemsPerRow));
        //     }
            
        //     return divided;
        // },
         menuClasses() {
            return {
                'offcanvas-menu': this.isOpen,
                'offcanvas-menu--right': this.isOpen && this.position === 'right',
            };
        },
    },
    methods: {
        // saveClose() {
        //     this.showMenu = false
        // },
        
    }
}
</script>
<style scoped lang="scss">
::v-deep {
     .offcanvas-content {
        position: fixed;
        top: 28% !important;
        width: 57vw !important;
        min-width: 72rem;
        overflow-y: hidden !important;
        height: 25rem !important;
        min-height: max-content !important;
        background-color: #fff;
        z-index: 9998;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.3s ease;
        overflow-y: auto;
        border-radius: 10px;
        right: 14px;
    }
    .offcanvas-overlay{
        width: 100% !important;
    }

}
h2{
    color: black;
}
.offcanvass-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    overflow-x: hidden;
    transition: width 0.5s ease;
    z-index: 9999;
    /* Adjust as needed */
}

.offcanvas-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}


.offcanvass-menu-right {
  transition: right 0.5s ease;
}

.activeCard {
    background-color: rgba(183, 220, 244, 0.879)
}

.personName {
    max-width: 17rem;
    width: 16rem;
    padding: 7px 0 !important;
}
.navheader {
    background-color: #EDF2F7;
}
.grid-row {
  display: flex;
  overflow-x: scroll;
}

.grid-item {
  /* Add your grid item styling here */
  border: 1px solid #000;
  width: 250px; /* Adjust width as needed */
  height: 80px; /* Adjust height as needed */
}
.input {
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    width: 20vw;
    font-size: 17px;
    height: 43px;
}
input[type="checkbox"]:checked {
  accent-color: transparent;
}
</style>
