export default {
    name: "Attributes",
    components: {

    },
    props: {
        individualData: Object,
        data: Object,
    },
    data() {
        return {
            type: "user",

        };
    },
    methods: {

    },
    computed: {
        getCheck: {
            get() {
                return this.individualData.show_on_graph;
            },
            set(value) {
                this.individualData.show_on_graph  = value
            },
        },
        getCompany:{
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Company Number");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Company Number");
                if (data) {
                    data.value = value;
                }
            },
        },
        getIncorporationDate:{
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Incorporation Date");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Incorporation Date");
                if (data) {
                    data.value = value;
                }
            },
        },
        getDissolutionDate:{
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Dissolution Date");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Dissolution Date");
                if (data) {
                    data.value = value;
                }
            },
        },
        getCountry:{
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Country");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Country");
                if (data) {
                    data.value = value;
                }
            },
        },
        getState:{
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "State(if USA selected)");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "State(if USA selected)");
                if (data) {
                    data.value = value;
                }
            },
        },
        getMainEntity: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Main Entity");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Main Entity");
                if (data) {
                    data.value = value;
                }
            },
        },
        getFirstName: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "First Name");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "First Name");
                if (data) {
                    data.value = value;
                }
            },
        },
        getMiddleName: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Middle Name");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Middle Name");
                if (data) {
                    data.value = value;
                }
            },
        },
        getLastName: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Last Name");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Last Name");
                if (data) {
                    data.value = value;
                }
            },
        },
        getDob: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "DOB");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "DOB");
                if (data) {
                    data.value = value;
                }
            },
        },
        getGender: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Gender");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Gender");
                if (data) {
                    data.value = value;
                }
            },
        },
        getNationality: {
            get() {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Nationality");
                return data ? data.value : null;
            },
            set(value) {
                const data = this.individualData.attributes.find(item => item.attribute_label === "Nationality");
                if (data) {
                    data.value = value;
                }
            },
        },
    },
};
