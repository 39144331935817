<template>
    <div class="flex" :class="{ 'h-full w-full' : isLoading}">
    <aside :class="{ 'w-full' : isLoading}">
    <div
      class="text-white px-2 py-2 rounded-t-lg entity flex items-center"
      :class="sidebarOpen ? 'w-12 h-10 justify-center ' : 'justify-between'"
    >
      <span :class="{ hidden: sidebarOpen }">Output Menu </span>
    </div>
    <div
      v-if="!sidebarOpen"
      class="sidebar cursor-pointer border-2 text-base-content"
      :class="{ 'h-1/2' : isLoading}"
    >
      <div
        v-if="isLoading"
        class="flex items-center h-full w-full justify-center min-w-10/12"
      >
        <Loader />
      </div>
      <ul v-else>
        <li
          v-for="item in navItems"
          :key="item.id"
          class="border-b-2 h-10 hover:bg-red-100"
          @click="selectPage(item)"
          :class="{ activeCard: isActive(item) }"
        >
          <div class="flex items-center">
            <span
              class="client-card-info self-stretch text-sm text-gray-600 font-small capitalize px-2 py-2"
            >
              {{ item.name }} {{ `(${item.count})` }}
            </span>
            <span
              v-if="item.children"
              class="icon text-base ml-auto mr-2 transition-transform transform ease-out duration-300"
              :class="expand ? ' rotate-180' : 'rotate-0'"
            >
              <font-awesome-icon icon="chevron-down" />
            </span>
            <component
              v-if="item.checkAlert"
              :is="icons.activity['bounceEmail']"
              class="mr-2"
            />
          </div>
          <ul v-if="item.children && expand" class="mt-1 p-0 border">
            <li
              v-for="child in item.children"
              :key="child.subname"
              class="sub-item items-center flex border-b-2 h-9 hover:bg-red-100"
              :class="{ activeCard: isActiveChild(child) }"
              @click="selectPage(child)"
            >
              <span
                class="pl-4 self-stretch w-56 text-sm text-gray-600 font-small capitalize px-2 py-2"
              >
                {{ child.subname }}
              </span>
              <span class="mr-2">
                <font-awesome-icon
                  :icon="['fas', 'plus']"
                  color="red"
                  size="xs"
                />
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div v-if="sidebarOpen && !isLoading" class="w-12 cursor-pointer border-2">
      <div
        v-if="isLoading"
        class="flex items-center h-full w-full justify-center"
      >
        <Loader />
      </div>
      <ul v-else>
        <li
          v-for="item in navItems"
          :key="item.id"
          class="border-b-2 h-10 hover:bg-red-100"
          @click="selectPage(item)"
          :class="{ activeCard: isActive(item) }"
        >
          <div class="flex items-center justify-center">
            <span
              class="self-stretch text-sm text-gray-600 font-small capitalize px-2 py-2 flex justify-center items-center"
            >
              {{ item.name[0] }}
            </span>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</div>
</template>
<script>
const bounceBackEmailIcon = () =>
  import("@/assets/icons/dashboard/activity/bounce-back-email.svg");
import Loader from "@/components/loader";
export default {
  props: ["navItems", "selectedPage", "menuCount", "sidebarOpen", "loading"],
  components: {
    Loader,
  },
  data() {
    return {
      expand: false,
      checkAlert: true,
      icons: {
        activity: {
          bounceEmail: bounceBackEmailIcon,
        },
      },
    };
  },
  computed: {
    isLoading() {
      return this.loading;
    },
  },
  methods: {
    sidebarToggle() {
      this.$emit("sideBarToggle", null);
      // this.sidebarOpen = !this.sidebarOpen;
    },
    childExpand() {
      this.expand = !this.expand;
    },
    selectPage(menuItem) {
      if (menuItem.children) {
        this.childExpand();
        this.$emit("sidebarItemClicked", menuItem.sub_id, true);
      } else {
        this.$emit("sidebarItemClicked", menuItem.id, true);
      }
    },
    isActive(item) {
      return this.selectedPage === item.id;
    },
    isActiveChild(child) {
      return this.selectedPage === child.subname;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/color.scss";

ul {
  list-style-type: none;
  padding: 0;
}

li {
  cursor: pointer;
}

.activeCard {
  background-color: #ffebeb;
}

.entity {
  border-bottom: 1px solid var(--gray, #e9e9ea);
  background: $lightray_product_color;
}

.sub-item {
}
</style>
