<template>
  <div class="">
    <div
          class="border-2 border-gray-200 flex h-10 items-center rounded-lg w-64"
          :class="{'bg-gray-200 cursor-not-allowed': disabled}"
        >
          <div class="flex justify-center px-1 py-2 w-16">
            <input type="checkbox" :checked="getCheckedValue" :v-model="checked" :disabled="disabled" class="focus:ring-blue-500 h-5 w-5  border-gray-300 rounded-md cursor-pointer" @change="handleChecked"/>
          </div>
          <div class="flex px-1 line-clamp-1 text-md" :title="getLabel">
            <span>{{ getLabel }}</span>
          </div>
        </div>
  </div>
</template>

<script>
export default {
  name: "check-box-card",
  props: {
    items: {
        type: Object,
        default: () => {},
        required: true,
    },
    type: {
      type: String,
      default: () => {},
      required: true,
    },
    fromRunIntelligence: {
      type: Boolean,
      default: false,
    },
    runIntelligenceRun: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getCheckedValue() {
      return this.items.checked;
    },
    getLabel() {
      let name =  this.items.label.replace("_", " ");
      return name.charAt(0).toUpperCase() + name.slice(1);
    },
    disabled () {
      return false;
      // if(this.fromRunIntelligence && this.getCheckedValue && this.runIntelligenceRun) {
      //   return true; 
      // } else {
      //   return false;
      // }
    }
  },
 
  components: {},
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    handleChecked(e) {
      this.$emit('input', {value: e.target.checked, id: this?.items?.id, type: this.type});
    },
  },
};
</script>
