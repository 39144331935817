<template>
    <div class="">
        <div :class="menuClasses">
            <div class="offcanvas-overlay" v-if="this.isOpen"></div>
            <div class="offcanvas-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "offcanvas",
    props: {
        isOpen: Boolean,
        position: String,
    },
    mounted() {

    },
    computed: {
        menuClasses() {
            return {
                'offcanvas-menu': this.isOpen,
                'offcanvas-menu--right': this.isOpen && this.position === 'right',
            };
        },

    },
};
</script>
<style scoped>

h2{
    color: black;
}
.offcanvass-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    overflow-x: hidden;
    transition: width 0.5s ease;
    z-index: 9999;
    /* Adjust as needed */
}

.offcanvas-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9998;
}

.offcanvas-content {
    position: fixed;
    top: 0;
    right: 0%;
    width: 85vw;
    height: 100%;
    background-color: #fff;
    z-index: 9998;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;    
    /* overflow-y: auto; */
}

.offcanvass-menu-right {
  transition: right 0.5s ease;
}

.activeCard {
    background-color: rgba(183, 220, 244, 0.879)
}</style>
  