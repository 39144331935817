<template>
  <div class="flex flex-row w-full">
    <div class="flex flex-col mr-2">
      <div class="filter_button relative mb-2 cursor-pointer" @click="filterToogle" :style="this.filter ? 'background-color:#910000' : ''">
            <span v-show="this.courtFilterCount >= 1" class="p-0.5 bg-white absolute -top-4 -right-3 rounded-full">
              <span  class="rounded-full py-0.5 px-2 text-xs text-white-text font-bold bg-red-500 z-50">{{ this.courtFilterCount }}</span>
            </span>
            <svg
              v-if="!this.filter"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
            >
              <path
                d="M4.66699 7.99935L0.666992 1.99935V0.666016H11.3337V1.99935L7.33366 7.99935V11.9993L4.66699 13.3327V7.99935Z"
                fill="#910000"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
            >
              <path
                d="M4.66699 7.99935L0.666992 1.99935V0.666016H11.3337V1.99935L7.33366 7.99935V11.9993L4.66699 13.3327V7.99935Z"
                fill="#ffffff"
              />
            </svg>
      </div>
      <div v-if="this.filter" class="w-60 p-1 border-2 rounded-lg flex flex-col justify-between" style="height: 60vh">
        <div class="w-56 relative flex flex-col filters_container overflow-y-scroll scroll" style="max-height: 50vh">
          <div class="w-full">
            <AccordionCard :isFilter="true" id="Jurisdiction" class="hidden">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="16"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      d="M4.8 7.8H4.79962C4.79962 7.49663 4.82475 7.63631 3.20494 4.39669C2.874 3.735 1.92638 3.73369 1.59487 4.39669C-0.0386251 7.66406 0.000375 7.50619 0.000375 7.8H0C0 8.62838 1.07456 9.3 2.4 9.3C3.72544 9.3 4.8 8.62838 4.8 7.8ZM2.4 4.8L3.75 7.5H1.05L2.4 4.8ZM11.9996 7.8C11.9996 7.49663 12.0248 7.63631 10.4049 4.39669C10.074 3.735 9.12638 3.73369 8.79488 4.39669C7.16138 7.66406 7.20038 7.50619 7.20038 7.8H7.2C7.2 8.62838 8.27456 9.3 9.6 9.3C10.9254 9.3 12 8.62838 12 7.8H11.9996ZM8.25 7.5L9.6 4.8L10.95 7.5H8.25ZM9.9 9.9H6.6V4.37344C7.04081 4.1805 7.37175 3.78319 7.46981 3.3H9.9C10.0658 3.3 10.2 3.16575 10.2 3V2.4C10.2 2.23425 10.0658 2.1 9.9 2.1H7.19325C6.9195 1.73775 6.48919 1.5 6 1.5C5.51081 1.5 5.0805 1.73775 4.80675 2.1H2.1C1.93425 2.1 1.8 2.23425 1.8 2.4V3C1.8 3.16575 1.93425 3.3 2.1 3.3H4.53019C4.62825 3.783 4.959 4.1805 5.4 4.37344V9.9H2.1C1.93425 9.9 1.8 10.0343 1.8 10.2V10.8C1.8 10.9658 1.93425 11.1 2.1 11.1H9.9C10.0658 11.1 10.2 10.9658 10.2 10.8V10.2C10.2 10.0343 10.0658 9.9 9.9 9.9Z"
                      fill="#910000"
                    />
                  </svg>
                  <span class="mx-1">Jurisdiction</span>
                </span>
              </template>
              <template v-slot:titleCheckbox>
                <div class="flex hidden">
                  <input
                    type="checkbox"
                    id="checkboxInput"
                    @change="$emit('handleAllCheckboxChange', $event, 'persons')"
                    :checked="isAllJurisdictionChecked"
                  />
                  <label class="mx-2 text-sm">Select all</label>
                </div>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <Input
                      class=""
                      @input="$emit('handleSearchboxChange', $event, 'namesTerm')"
                      icon="search"
                      placeholder="Search people"
                    />
                  </div>
                  <div class="grid grid-cols-1 py-2 pl-2">
                    <div c v-for="person in getJurisdiction" :key="person.name">
                      <input
                        type="checkbox"
                        @change="$emit('handleCourtCheckboxChange')"
                        :id="person.key"
                        v-model="person.checked"
                        class=""
                      />
                      <label class="filter-item-label mx-2">{{
                        person.key
                      }}</label>
                    </div>
                  </div>
                  <!-- <span
                    v-if="seeMorePeople"
                    @click="$emit('handleSeeMore', true, 'persons')"
                    class="f-title cursor-pointer pl-2"
                  >
                    Load More
                  </span> -->
                </div>
              </template>
            </AccordionCard>
            <AccordionCard :isFilter="true" id="Filling Date">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_10231_33796)">
                      <path
                        d="M0.5 11.375C0.5 11.9961 1.00391 12.5 1.625 12.5H9.875C10.4961 12.5 11 11.9961 11 11.375V5H0.5V11.375ZM8 6.78125C8 6.62656 8.12656 6.5 8.28125 6.5H9.21875C9.37344 6.5 9.5 6.62656 9.5 6.78125V7.71875C9.5 7.87344 9.37344 8 9.21875 8H8.28125C8.12656 8 8 7.87344 8 7.71875V6.78125ZM8 9.78125C8 9.62656 8.12656 9.5 8.28125 9.5H9.21875C9.37344 9.5 9.5 9.62656 9.5 9.78125V10.7188C9.5 10.8734 9.37344 11 9.21875 11H8.28125C8.12656 11 8 10.8734 8 10.7188V9.78125ZM5 6.78125C5 6.62656 5.12656 6.5 5.28125 6.5H6.21875C6.37344 6.5 6.5 6.62656 6.5 6.78125V7.71875C6.5 7.87344 6.37344 8 6.21875 8H5.28125C5.12656 8 5 7.87344 5 7.71875V6.78125ZM5 9.78125C5 9.62656 5.12656 9.5 5.28125 9.5H6.21875C6.37344 9.5 6.5 9.62656 6.5 9.78125V10.7188C6.5 10.8734 6.37344 11 6.21875 11H5.28125C5.12656 11 5 10.8734 5 10.7188V9.78125ZM2 6.78125C2 6.62656 2.12656 6.5 2.28125 6.5H3.21875C3.37344 6.5 3.5 6.62656 3.5 6.78125V7.71875C3.5 7.87344 3.37344 8 3.21875 8H2.28125C2.12656 8 2 7.87344 2 7.71875V6.78125ZM2 9.78125C2 9.62656 2.12656 9.5 2.28125 9.5H3.21875C3.37344 9.5 3.5 9.62656 3.5 9.78125V10.7188C3.5 10.8734 3.37344 11 3.21875 11H2.28125C2.12656 11 2 10.8734 2 10.7188V9.78125ZM9.875 2H8.75V0.875C8.75 0.66875 8.58125 0.5 8.375 0.5H7.625C7.41875 0.5 7.25 0.66875 7.25 0.875V2H4.25V0.875C4.25 0.66875 4.08125 0.5 3.875 0.5H3.125C2.91875 0.5 2.75 0.66875 2.75 0.875V2H1.625C1.00391 2 0.5 2.50391 0.5 3.125V4.25H11V3.125C11 2.50391 10.4961 2 9.875 2Z"
                        fill="#910000"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_10231_33796">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="mx-1">Filling Date</span>
                </span>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <label>From</label>
                    <input
                      placeholder="Enter"
                      type="date"
                      min="1900-01-01" 
                      class="input input-bordered border-box-stroke focus:border-primary"
                      id="from"
                      v-model="fromDate"
                      @input="updateFrom"
                    />
                    <label>To</label>
                    <input
                      placeholder="Enter"
                      type="date"
                      min="1900-01-01" 
                      class="input input-bordered border-box-stroke focus:border-primary"
                      id="to"
                      v-model="toDate"
                      @input="updateTo"
                    />
                    <p class="mt-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-small text-red-700 ring-1 ring-inset ring-red-600/10" v-if="dateError">{{ dateError }}</p>
                  </div>
                </div>
              </template>
            </AccordionCard>
            <AccordionCard :isFilter="true" id="Case Status">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_10231_33803)">
                      <path
                        d="M11.2188 4.25C11.3734 4.25 11.5 4.12344 11.5 3.96875V3.03125C11.5 2.87656 11.3734 2.75 11.2188 2.75H10.75V1.625C10.75 1.00391 10.2461 0.5 9.625 0.5H2.125C1.50391 0.5 1 1.00391 1 1.625V11.375C1 11.9961 1.50391 12.5 2.125 12.5H9.625C10.2461 12.5 10.75 11.9961 10.75 11.375V10.25H11.2188C11.3734 10.25 11.5 10.1234 11.5 9.96875V9.03125C11.5 8.87656 11.3734 8.75 11.2188 8.75H10.75V7.25H11.2188C11.3734 7.25 11.5 7.12344 11.5 6.96875V6.03125C11.5 5.87656 11.3734 5.75 11.2188 5.75H10.75V4.25H11.2188ZM5.875 3.5C6.70234 3.5 7.375 4.17266 7.375 5C7.375 5.82734 6.70234 6.5 5.875 6.5C5.04766 6.5 4.375 5.82734 4.375 5C4.375 4.17266 5.04766 3.5 5.875 3.5ZM8.5 9.05C8.5 9.29844 8.26562 9.5 7.975 9.5H3.775C3.48438 9.5 3.25 9.29844 3.25 9.05V8.6C3.25 7.85469 3.95547 7.25 4.825 7.25H4.94219C5.23047 7.36953 5.54453 7.4375 5.875 7.4375C6.20547 7.4375 6.52187 7.36953 6.80781 7.25H6.925C7.79453 7.25 8.5 7.85469 8.5 8.6V9.05Z"
                        fill="#910000"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_10231_33803">
                        <rect
                          width="12"
                          height="12"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="mx-1">Case Status {{`(${getStatusCount})`}}</span>
                </span>
              </template>
              <template v-slot:titleCheckbox>
                <div class="flex hidden">
                  <input
                    type="checkbox"
                    id="checkboxInput"
                    @change="$emit('handleAllCheckboxChange', $event, 'persons')"
                    :checked="isAllJurisdictionChecked"
                  />
                  <label class="mx-2 text-sm">Select all</label>
                </div>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <Input
                      class=""
                      v-model="searchData.caseType"
                      icon="search"
                      placeholder="Search Case Status"
                    />
                  </div>
                  <div class="grid grid-cols-1 py-2 pl-2">
                    <div c v-for="person in getStatusFilter" :key="person.name">
                      <input
                        type="checkbox"
                        @change="
                          $emit(
                            'handleCheckboxChange',
                            $event,
                            person.key,
                            'persons'
                          )
                        "
                        :id="person.key"
                        v-model="person.checked"
                        class=""
                      />
                      <label class="filter-item-label mx-2">{{
                        person.key
                      }}</label>
                    </div>
                  </div>
                  <!-- <span
                    v-if="seeMorePeople"
                    @click="$emit('handleSeeMore', true, 'persons')"
                    class="f-title cursor-pointer pl-2"
                  >
                    Load More
                  </span> -->
                </div>
              </template>
            </AccordionCard>
            <AccordionCard :isFilter="true" id="Case Number">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_10231_33810)">
                      <path
                        d="M6.75 3.6875V0.5H2.0625C1.75078 0.5 1.5 0.750781 1.5 1.0625V11.9375C1.5 12.2492 1.75078 12.5 2.0625 12.5H9.9375C10.2492 12.5 10.5 12.2492 10.5 11.9375V4.25H7.3125C7.00313 4.25 6.75 3.99688 6.75 3.6875ZM8.25 9.21875C8.25 9.37344 8.12344 9.5 7.96875 9.5H4.03125C3.87656 9.5 3.75 9.37344 3.75 9.21875V9.03125C3.75 8.87656 3.87656 8.75 4.03125 8.75H7.96875C8.12344 8.75 8.25 8.87656 8.25 9.03125V9.21875ZM8.25 7.71875C8.25 7.87344 8.12344 8 7.96875 8H4.03125C3.87656 8 3.75 7.87344 3.75 7.71875V7.53125C3.75 7.37656 3.87656 7.25 4.03125 7.25H7.96875C8.12344 7.25 8.25 7.37656 8.25 7.53125V7.71875ZM8.25 6.03125V6.21875C8.25 6.37344 8.12344 6.5 7.96875 6.5H4.03125C3.87656 6.5 3.75 6.37344 3.75 6.21875V6.03125C3.75 5.87656 3.87656 5.75 4.03125 5.75H7.96875C8.12344 5.75 8.25 5.87656 8.25 6.03125ZM10.5 3.35703V3.5H7.5V0.5H7.64297C7.79297 0.5 7.93594 0.558594 8.04141 0.664062L10.3359 2.96094C10.4414 3.06641 10.5 3.20938 10.5 3.35703Z"
                        fill="#910000"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_10231_33810">
                        <rect
                          width="12"
                          height="12"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="mx-1">Case Number  {{`(${getCaseNumberCount})`}}</span>
                </span>
              </template>
              <template v-slot:titleCheckbox>
                <div class="flex hidden">
                  <input
                    type="checkbox"
                    id="checkboxInput"
                    @change="$emit('handleAllCheckboxChange', $event, 'persons')"
                    :checked="isAllJurisdictionChecked"
                  />
                  <label class="mx-2 text-sm">Select all</label>
                </div>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <Input
                      class=""
                      v-model="searchData.caseName"
                      icon="search"
                      placeholder="Search Case Number"
                    />
                  </div>
                  <div class="grid grid-cols-1 py-2 pl-2">
                    <div
                      c
                      v-for="caseNumber in getCaseNumber"
                      :key="caseNumber.name"
                    >
                      <input
                        type="checkbox"
                        @change="$emit('handleCourtCheckboxChange')"
                        :id="caseNumber.key"
                        v-model="caseNumber.checked"
                        class=""
                      />
                      <label class="filter-item-label mx-2">{{
                        caseNumber.key
                      }}</label>
                    </div>
                  </div>
                  <!-- <span
                    v-if="seeMorePeople"
                    @click="$emit('handleSeeMore', true, 'persons')"
                    class="f-title cursor-pointer pl-2"
                  >
                    Load More
                  </span> -->
                </div>
              </template>
            </AccordionCard>
            <AccordionCard :isFilter="true" id="Planitiff">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_10231_33803)">
                      <path
                        d="M11.2188 4.25C11.3734 4.25 11.5 4.12344 11.5 3.96875V3.03125C11.5 2.87656 11.3734 2.75 11.2188 2.75H10.75V1.625C10.75 1.00391 10.2461 0.5 9.625 0.5H2.125C1.50391 0.5 1 1.00391 1 1.625V11.375C1 11.9961 1.50391 12.5 2.125 12.5H9.625C10.2461 12.5 10.75 11.9961 10.75 11.375V10.25H11.2188C11.3734 10.25 11.5 10.1234 11.5 9.96875V9.03125C11.5 8.87656 11.3734 8.75 11.2188 8.75H10.75V7.25H11.2188C11.3734 7.25 11.5 7.12344 11.5 6.96875V6.03125C11.5 5.87656 11.3734 5.75 11.2188 5.75H10.75V4.25H11.2188ZM5.875 3.5C6.70234 3.5 7.375 4.17266 7.375 5C7.375 5.82734 6.70234 6.5 5.875 6.5C5.04766 6.5 4.375 5.82734 4.375 5C4.375 4.17266 5.04766 3.5 5.875 3.5ZM8.5 9.05C8.5 9.29844 8.26562 9.5 7.975 9.5H3.775C3.48438 9.5 3.25 9.29844 3.25 9.05V8.6C3.25 7.85469 3.95547 7.25 4.825 7.25H4.94219C5.23047 7.36953 5.54453 7.4375 5.875 7.4375C6.20547 7.4375 6.52187 7.36953 6.80781 7.25H6.925C7.79453 7.25 8.5 7.85469 8.5 8.6V9.05Z"
                        fill="#910000"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_10231_33803">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="mx-1"> Plaintiff {{`(${getPlaintiffCount})`}}</span>
                </span>
              </template>
              <template v-slot:titleCheckbox>
                <div class="flex hidden">
                  <input
                    type="checkbox"
                    id="checkboxInput"
                    @change="$emit('handleAllCheckboxChange', $event, 'persons')"
                    :checked="isAllJurisdictionChecked"
                  />
                  <label class="mx-2 text-sm">Select all</label>
                </div>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <Input
                      class=""
                      @input="$emit('handleSearchboxChange', $event, 'namesTerm')"
                      v-model="searchData.plaintiff"
                      icon="search"
                      placeholder="Search Plaintiff"
                    />
                  </div>
                  <div class="grid grid-cols-1 py-2 pl-2">
                    <div c v-for="person in getPlaintiff" :key="person.name">
                      <input
                        type="checkbox"
                        @change="$emit('handleCourtCheckboxChange')"
                        :id="person.key"
                        v-model="person.checked"
                        class=""
                      />
                      <label class="filter-item-label mx-2" :title="person.key">{{
                        ( person.key.length > 15 ) ? person.key.substr(0,15)+'...' : person.key
                      }}</label>
                    </div>
                  </div>
                  <!-- <span
                    v-if="seeMorePeople"
                    @click="$emit('handleSeeMore', true, 'persons')"
                    class="f-title cursor-pointer pl-2"
                  >
                    Load More
                  </span> -->
                </div>
              </template>
            </AccordionCard>
            <AccordionCard :isFilter="true" id="Defendants">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      d="M8.15063 3H6.54C6.39 3 6.24563 3.05625 6.135 3.1575L4.29187 4.845C4.29 4.84687 4.28813 4.85063 4.28625 4.8525C3.975 5.145 3.98063 5.61188 4.24688 5.9025C4.485 6.16313 4.98563 6.2325 5.29875 5.95312C5.30063 5.95125 5.30438 5.95125 5.30625 5.94938L6.80438 4.57688C6.92625 4.46625 7.1175 4.47375 7.22813 4.59563C7.34063 4.7175 7.33125 4.90688 7.20938 5.01938L6.72 5.4675L9.45 7.68375C9.50438 7.72875 9.55313 7.7775 9.59813 7.82812V4.2L8.57438 3.17625C8.46375 3.06375 8.31 3 8.15063 3ZM10.2 4.20375V8.40188C10.2 8.73375 10.4681 9.00188 10.8 9.00188H12V4.20375H10.2ZM11.1 8.40188C10.935 8.40188 10.8 8.26688 10.8 8.10188C10.8 7.93688 10.935 7.80188 11.1 7.80188C11.265 7.80188 11.4 7.93688 11.4 8.10188C11.4 8.26688 11.265 8.40188 11.1 8.40188ZM0 9H1.2C1.53188 9 1.8 8.73188 1.8 8.4V4.20375H0V9ZM0.9 7.80188C1.065 7.80188 1.2 7.93688 1.2 8.10188C1.2 8.26688 1.065 8.40188 0.9 8.40188C0.735 8.40188 0.6 8.26688 0.6 8.10188C0.6 7.935 0.735 7.80188 0.9 7.80188ZM9.07312 8.15063L6.27375 5.87813L5.71125 6.39375C5.15438 6.90188 4.30125 6.85313 3.80437 6.31125C3.3 5.76 3.33938 4.90687 3.88688 4.40437L5.42063 3H3.84938C3.69 3 3.53813 3.06375 3.42563 3.17625L2.4 4.2V8.39813H2.74313L4.44 9.93375C4.95375 10.3519 5.70937 10.2731 6.1275 9.75938L6.13125 9.75563L6.46688 10.0462C6.765 10.29 7.20563 10.2431 7.4475 9.945L8.03625 9.22125L8.1375 9.30375C8.39438 9.51188 8.77313 9.47438 8.98125 9.21563L9.15938 8.99625C9.36938 8.7375 9.33 8.36063 9.07312 8.15063Z"
                      fill="#910000"
                    />
                  </svg>
                  <span class="mx-1">Defendants {{`(${getDefendantCount})`}}</span>
                </span>
              </template>
              <template v-slot:titleCheckbox>
                <div class="flex hidden">
                  <input
                    type="checkbox"
                    id="checkboxInput"
                    @change="$emit('handleAllCheckboxChange', $event, 'persons')"
                    :checked="isAllJurisdictionChecked"
                  />
                  <label class="mx-2 text-sm">Select all</label>
                </div>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <Input
                      class=""
                      @input="$emit('handleSearchboxChange', $event, 'namesTerm')"
                      icon="search"
                      v-model=searchData.defendant
                      placeholder="Search Defendants"
                    />
                  </div>
                  <div class="grid grid-cols-1 py-2 pl-2">
                    <div c v-for="person in getDefendant" :key="person.name">
                      <input
                        type="checkbox"
                        @change="$emit('handleCourtCheckboxChange')"
                        :id="person.key"
                        v-model="person.checked"
                        class=""
                      />
                      <label class="filter-item-label mx-2" :title="person.key">{{
                        ( person.key.length > 15 ) ? person.key.substr(0,15)+'...' : person.key
                      }}</label>
                    </div>
                  </div>
                  <!-- <span
                    v-if="seeMorePeople"
                    @click="$emit('handleSeeMore', true, 'persons')"
                    class="f-title cursor-pointer pl-2"
                  >
                    Load More
                  </span> -->
                </div>
              </template>
            </AccordionCard>
            <AccordionCard :isFilter="true" id="Nature/Cause of Action ">
              <template v-slot:title>
                <span class="f_title flex items-center w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_10231_33830)">
                      <path
                        d="M8.19293 2.31522C6.93698 2.31522 5.89971 1.49999 4.32066 1.49999C3.73577 1.49999 3.21185 1.60274 2.72585 1.78155C2.79321 1.60564 2.82187 1.41729 2.8099 1.22931C2.76837 0.562843 2.22202 0.0282574 1.5548 0.00111674C0.804961 -0.0293989 0.1875 0.569429 0.1875 1.31249C0.1875 1.75841 0.410086 2.15214 0.75 2.38933V11.4375C0.75 11.7481 1.00184 12 1.3125 12H1.6875C1.99816 12 2.25 11.7481 2.25 11.4375V9.22499C2.91354 8.94224 3.7402 8.7065 4.93207 8.7065C6.18804 8.7065 7.22529 9.52173 8.80434 9.52173C9.9333 9.52173 10.8356 9.13984 11.6756 8.56412C11.8791 8.42464 12 8.19306 12 7.94635V2.2487C12 1.70043 11.4312 1.33769 10.9339 1.56864C10.1291 1.94242 9.14205 2.31522 8.19293 2.31522Z"
                        fill="#910000"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_10231_33830">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span class="mx-1">Nature/Cause of Action  {{`(${getCaseTypeCount})`}}</span>
                </span>
              </template>
              <template v-slot:titleCheckbox>
                <div class="flex hidden">
                  <input
                    type="checkbox"
                    id="checkboxInput"
                    @change="$emit('handleAllCheckboxChange', $event, 'persons')"
                    :checked="isAllJurisdictionChecked"
                  />
                  <label class="mx-2 text-sm">Select all</label>
                </div>
              </template>
              <template v-slot:content>
                <div class="p-2">
                  <div class="form-control">
                    <Input
                      class=""
                      v-model="searchData.causeOfAction"
                      icon="search"
                      placeholder="Search Nature/Cause of Action"
                    />
                  </div>
                  <div class="grid grid-cols-1 py-2 pl-2">
                    <div c v-for="person in getCaseType" :key="person.name">
                      <input
                        type="checkbox"
                        @change="
                          $emit(
                            'handleCheckboxChange',
                            $event,
                            person.key,
                            'persons'
                          )
                        "
                        :id="person.key"
                        v-model="person.checked"
                        class=""
                      />
                      <label class="filter-item-label mx-2" :title="person.key">{{
                        ( person.key.length > 15 ) ? person.key.substr(0,15)+'...' : person.key
                      }}</label>
                    </div>
                  </div>
                  <!-- <span
                    v-if="seeMorePeople"
                    @click="$emit('handleSeeMore', true, 'persons')"
                    class="f-title cursor-pointer pl-2"
                  >
                    Load More
                  </span> -->
                </div>
              </template>
            </AccordionCard>
          </div>
        </div>
        <div class="flex justify-evenly border-t-2 py-2">
          <Button @click="resetFilter" :outline="true" text="Reset" class="button_red_outline"></Button>
          <Button @click="$emit('applyCourtFilter')" text="Apply" class="button_red"></Button>
        </div>
      </div>
    </div> 
    <!-- <div class="flex w-full justify-center items-center"> -->
      <div class="w-full flex justify-center items-center">
        <div v-if="loading"  class="flex justify-center items-center w-full" style="height: 60vh">
          <Loader />
        </div>
        <div v-else-if="getCourtRecords.length" id="scrollContainer" class=" flex w-full overflow-y-scroll flex-col scroll" style="max-height: 60vh" >
          <div v-for="(item, idx) in getCourtRecords" :key="item.id" class="custom_card border rounded-lg p-4 mx-2 mb-2 bg-white" :class="idx == getCourtRecords.length - 1 ? 'mb-40' : ''">
            <div class="flex item-center justify-center w-full">
              <div v-if="!isShrink" class="data-section w-full">
                <div class="header-section w-full mb-1 flex justify-between">
                  <div>
                    <a v-if='item.source_name ==	"judyrecords" && !item?.entity_meta_data?.is_details_scraped' class="text-black header-link" :href="item.url"  target="_blank">{{ item?.entity_meta_data?.jurisdiction}}</a>
                    <a v-else class="text-black header-link"  target="_blank">{{ item.entity.substr(0, 100) }}</a>
                  </div>
                  <div class="flex">
                    <CanvasMenu class="cursor-pointer" :isMainenity="false" :individualData="item" :mainEntity="mainEntity" :sourceName="sourceName" :confidence="true" @stateClear="updateSelectedPage" />
                    <div class="mx-1 label-box dropdown_parent dropdown dropdown-end" :class="getStatus(item)" >
                      <div class="label" @click="()=>toogleDropdown(idx)"  tabindex="0">
                        <MinusIcon  v-if="getStatus(item) == 'unknown'"  class="cursor-pointer"/>
                        <Check  v-if="getStatus(item) == 'confirmed'"    class="cursor-pointer"/>
                        <Question  v-if="getStatus(item) == 'potential'" class="cursor-pointer"/>
                        <Cross  v-if="getStatus(item) == 'irrelevant'"   class="cursor-pointer"/>
                      </div>
                      <ul v-if="showList[idx]" class="dropdown-content dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2 card" tabindex="0">
                        <li @mousedown="selectDropdown($event,idx)" name="confirmed" class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer">
                          <span name="confirmed" class="" style="color: #67b52b">Confirmed</span>
                          <span name="confirmed" class="badge" style="background: #f0f8ea"><Check name="confirmed"/></span></li>
                        <li @mousedown="selectDropdown($event,idx)" name="potential" class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer">
                          <span name="potential" class="" style="color: #fc6713">Potential</span>
                          <span name="potential" class="badge" style="background: rgba(252, 103, 19, 0.11)"><Question name="potential" /></span>
                        </li>
                        <li @mousedown="selectDropdown($event,idx)" name="irrelevant" class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer">
                          <span name="irrelevant" class="" style="color: #eb3131">Irrelevant</span>
                          <span name="irrelevant" class="badge" style="background: #fbeded"><Cross name="irrelevant" /></span>
                        </li>
                        <li @mousedown="selectDropdown($event,idx)" name="unknown" class="p-2 flex items-center justify-between">
                          <span name="unknown" class="" style="color: #8d8d8d">unknown</span>
                          <span name="unknown" class="badge" style="background: #d7d7d7"><MinusIcon name="unknown"/></span>
                        </li>
                      </ul>
                    </div>
                    <div class="mx-1"><Exclamation class="cursor-pointer" :fill="riskcolor(item)"/></div>
                    <div class="mx-1"> <CanvasMenu class="cursor-pointer" :isMainenity="false" :individualData="item" :mainEntity="mainEntity" :sourceName="sourceName" @stateClear="updateSelectedPage" /></div>
                  </div>
                </div>
                <div class="flex">
                  <span class="text_heading">Jurisdiction :</span>
                  <span class="text_value mx-2">{{  item?.entity_meta_data?.jurisdiction  }}</span>
                  <span class="text_heading">Case Type :</span>
                  <span class="text_value mx-2">{{ item?.entity_meta_data?.case_type || "-" }}</span>
                </div>
                <div class="flex p-0 m-0">
                  <div class="border-r-2 mr-3">
                    <span class="text_heading">Case Number :</span>
                    <span class="text_value ml-2 mr-4">{{ item?.entity_meta_data?.case_number || "-" }}</span>
                  </div>
                  <div class="border-r-2 mr-3">
                    <span class="text_heading">Filling Date :</span>
                    <span class="text_value ml-2 mr-4">{{getFillingDate(item?.entity_meta_data?.filling_date)}}</span>
                  </div>
                  <div>
                    <span class="text_heading">Case Status :</span>
                    <span class="text_value ml-2 mr-4">{{ item?.entity_meta_data?.case_status || "-" }}</span>
                  </div>
                </div>
                <div class="flex p-0 m-0" v-if="item?.entity_meta_data?.judges">
                    <div class="mr-3">
                      <span class="text_heading">Judges :</span>
                      <span class="text_value ml-2 mr-4" :title="getJudgeTitle(item)"> {{ getJudge(item) }}</span>
                    </div>
                    <div class="mr-3">
                      <span class="text_heading">Plaintiff :</span>
                      <span class="text_value ml-2 mr-4" :title="`${item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'PLAINTIFF').map(i => i.name).join(',')}`"
                      >{{ item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'PLAINTIFF').map(i => i.name).join(" ").substr(0,30)+'...' }}</span>
                    </div>
                    <div>
                      <span class="text_heading">Defendant :</span>
                      <span class="text_value ml-2 mr-4" :title="`${ item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'DEFENDANT').map(i => i.name).join(',') }`"
                      >{{ item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'DEFENDANT').map(i => i.name).join(" ").substr(0,30)+'...'  }}</span>
                    </div>
                </div>
                <div class="flex flex-wrap items-center">
                  <div  v-for="(itm, indx) in getRiskCategories(item)" :key="indx" class="label mr-3 my-2 rounded-full"  :class="checkCategories(itm)">
                    <span>{{ itm }}</span>
                  </div>
                  <!-- <span v-if="showSeeMore(item)"  @click="toggleSeeMore"  class="f-title cursor-pointer pl-2">  {{ seeMore ? "Show Less" : "Load More" }}</span> -->
                </div>
                <p v-show='item.source_name ==	"judyrecords"' :title="item?.snippet || ''">{{item?.source_name ==	"judyrecords" &&  typeof item?.snippet === 'string'? item?.snippet?.substr(0,70)+'...' : ''}}<p/>
              </div>
              <div v-if="isShrink" class="w-full flex justify-between">
                <div>
                  <div>
                    <a v-if='item.source_name ==	"judyrecords" && !item.entity_meta_data.is_details_scraped' class="text-black header-link" :href="item.url"  target="_blank">{{ item?.entity_meta_data.jurisdiction}}</a>
                    <a v-else class="text-black header-link"  target="_blank">{{ item.entity.substr(0, 100) }}</a>
                  </div>
                  <div class="flex flex-col">
                    <div>
                      <span class="text_heading">Jurisdiction :</span>
                      <span class="text_value mx-2">{{
                        item?.entity_meta_data?.jurisdiction || "-"
                      }}</span>
                    </div>
                    <div>
                      <span class="text_heading">Case Type :</span>
                      <span class="text_value mx-2">{{
                        item?.entity_meta_data?.case_type || "-"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex flex-col p-0 m-0">
                    <div class="">
                      <span class="text_heading">Case Number :</span>
                      <span class="text_value ml-2 mr-4">{{
                        item?.entity_meta_data?.case_number || "-"
                      }}</span>
                    </div>
                    <div class="">
                      <span class="text_heading">Filling Date :</span>
                      <span class="text_value ml-2 mr-4">{{ getFillingDate(item?.entity_meta_data?.filling_date) || "-"}}</span>
                    </div>
                    <div>
                      <span class="text_heading">Case Status :</span>
                      <span class="text_value ml-2 mr-4">{{
                        item?.entity_meta_data?.case_status || "-"
                      }}</span>
                    </div>
                  </div>
                  <div class="flex p-0 m-0" v-if="item?.entity_meta_data?.judges">
                    <div class="mr-3">
                      <span class="text_heading">Judges :</span>
                      <span class="text_value ml-2 mr-4" :title="`${item?.entity_meta_data?.judges.map(i => `${i.name}`).join(',')}`">{{  item?.entity_meta_data?.judges.map(i => `${i.name}`).join(',').substr(0,5)+'...' }}</span>
                    </div>
                    <div class="mr-3">
                      <span class="text_heading">Plaintiff :</span>
                      <span class="text_value ml-2 mr-4" :title="`${item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'PLAINTIFF').map(i => i.name).join(',')}`"
                      >{{ item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'PLAINTIFF').map(i => i.name).join(" ").substr(0,5)+'...' }}</span>
                    </div>
                    <div>
                      <span class="text_heading">Defendant :</span>
                      <span class="text_value ml-2 mr-4" :title="`${ item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'DEFENDANT').map(i => i.name).join(',') }`"
                      >{{ item?.entity_meta_data?.parties.filter(item => item.party_role.toUpperCase() === 'DEFENDANT').map(i => i.name).join(" ").substr(0,5)+'...'  }}</span>
                    </div>
                  </div>
                  <div class="flex flex-wrap items-center">
                    <div  v-for="(itm, indx) in getRiskCategories(item)" :key="indx" class="label mr-3 my-2 rounded-full"  :class="checkCategories(itm)">
                      <span>{{ itm }}</span>
                    </div>
                    <p v-show='item.source_name ==	"judyrecords"' :title="item?.snippet">{{item.source_name ==	"judyrecords" ? item?.snippet.substr(0,70)+'...' : ''}}<p/>
                  </div>
                </div>
                <div class="flex flex-col items-center">
                  <CanvasMenu
                    class="mb-2 cursor-pointer"
                    :isMainenity="false"
                    :individualData="item"
                    :mainEntity="mainEntity"
                    :sourceName="sourceName"
                    :confidence="true"
                    @stateClear="updateSelectedPage"
                  />
                  <div class="mb-2 label-box dropdown_parent dropdown dropdown-end" :class="getStatus(item)" >
                      <div class="label" @click="()=>toogleDropdown(idx)"  tabindex="0">
                        <MinusIcon  v-if="getStatus(item) == 'unknown'"  class="cursor-pointer"/>
                        <Check  v-if="getStatus(item) == 'confirmed'"    class="cursor-pointer"/>
                        <Question  v-if="getStatus(item) == 'potential'" class="cursor-pointer"/>
                        <Cross  v-if="getStatus(item) == 'irrelevant'"   class="cursor-pointer"/>
                      </div>
                      <ul v-if="showList[idx]" class="dropdown-content dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2 card" tabindex="0">
                        <li @mousedown="selectDropdown($event,idx)" name="confirmed" class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer">
                          <span name="confirmed" class="" style="color: #67b52b">Confirmed</span>
                          <span name="confirmed" class="badge" style="background: #f0f8ea"><Check name="confirmed"/></span></li>
                        <li @mousedown="selectDropdown($event,idx)" name="potential" class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer">
                          <span name="potential" class="" style="color: #fc6713">Potential</span>
                          <span name="potential" class="badge" style="background: rgba(252, 103, 19, 0.11)"><Question name="potential" /></span>
                        </li>
                        <li @mousedown="selectDropdown($event,idx)" name="irrelevant" class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer">
                          <span name="irrelevant" class="" style="color: #eb3131">Irrelevant</span>
                          <span name="irrelevant" class="badge" style="background: #fbeded"><Cross name="irrelevant" /></span>
                        </li>
                        <li @mousedown="selectDropdown($event,idx,)" name="unknown" class="p-2 flex items-center justify-between">
                          <span name="unknown" class="" style="color: #8d8d8d">unknown</span>
                          <span name="unknown" class="badge" style="background: #d7d7d7"><MinusIcon name="unknown"/></span>
                        </li>
                      </ul>
                    </div>
                  <div class="mb-2 ">
                    <Exclamation class="cursor-pointer" :fill="riskcolor(item)" />
                  </div>
                  <div class="mb-2">
                    <CanvasMenu
                      class="cursor-pointer"
                      :isMainenity="false"
                      :individualData="item"
                      :mainEntity="mainEntity"
                      :sourceName="sourceName" 
                      @stateClear="updateSelectedPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col justify-center items-center"  style="height: 60vh">
          <div>
            <NoDataIcon />
          </div>
          <p class>No Data Found.</p>
        </div>
      </div>
    <!-- </div> -->
  </div>
</template>
<script>
import NoDataIcon from "../../assets/svg/nodatafound.svg";
import Loader from "@/components/loader";
import Button from "../../../components/button";
import Exclamation from "@shared/assets/exclamation-red.svg";
import CanvasMenu from "../../components/canvas-menu";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "../../assets/svg/check.svg";
import Question from "../../assets/svg/question.svg";
import Cross from "../../assets/svg/cross-red.svg";
import AccordionCard from "../../common/accordion";
import Input from "../../../input";
import { getPlantifData, getJudgyData, saveUnicourtData, updateEntityDetails ,savejudyData} from "../../services.js";
import {DateTime} from "luxon";  
export default {
  components: {
    NoDataIcon,
    Input,
    Exclamation,
    CanvasMenu,
    MinusIcon,
    Check,
    Question,
    Cross,
    Button,
    AccordionCard,
    Loader,
  },
  data() {
    return {
      showList: [],
      status: "unknown",
      CaseError:false,
      seeMore: false,
      filter: false,
      filterData: [],
      searchData:{
        caseName:"",
        caseType:"",
        plaintiff:"",
        defendant:"",
        causeOfAction:""
      },
      fromDate : this.filling_from_date,
      toDate : this.filling_to_date,  
      dateError: '',
      loader: false,
    };
  },
  props: {
    totalPages: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    handleCourt: {
      type: Array,
      default: () => [],
    },
    mainEntity: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: "",
    },
    sourceName: {
      type: String,
      default: "",
    },
    getOptions: {
      type: Array,
      default: () => [],
    },
    shrink: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Put Some Title",
    },
    courtFilterData: {
      type: Object,
      default: () => {},
    },
    courtFilterCount: {
      type: Number,
      default: 0,
    },
    filling_from_date: {
      type: String,
      default: "",
    },
    filling_to_date: {
      type: String,
      default: "",
    },
  },
  computed: {
    isShrink() {
      return this.shrink && this.filter ? true : false;
    },
    isAllJurisdictionChecked() {
      // const checked = this.filterData.names.filter(item => item.checked)
      // const count = this.filterData.names.length
      // return checked.length === count && count !== 0
      return true;
    },
    getPeople() {
      let arr = [
        { key: "Delhi", checked: true },
        { key: "Delhi", checked: true },
      ];
      return arr;
    },
    getStatusFilter() {
      let arr = this.courtFilterData["case_status"];
      if(Array.isArray(arr)){
        arr  = arr.filter(item=>this.caseInsensitiveSearch(item.key,this.searchData?.caseType));
      }
      return arr;
    }, 
    getStatusCount(){
      let arr = this.courtFilterData["case_status"]
      let count = 0;
      if(Array.isArray(arr)){
         arr.forEach(item => {if(item.checked){ count++ }});
      }
      return count;
    },
    getCaseNumber() {
      let arr = this.courtFilterData["case_number"]
      if(Array.isArray(arr)){
        arr  = arr.filter(item=>this.caseInsensitiveSearch(item.key,this.searchData?.caseName));
      }
      return arr;
    },
    getCaseNumberCount(){
      let arr = this.courtFilterData["case_number"]
      let count = 0;
      if(Array.isArray(arr)){
         arr.forEach(item => {if(item.checked){ count++ }});
      }
      return count;
    },
    getJurisdiction(){
      let arr = this.courtFilterData["jurisdiction"]
      return arr;
    },
    getPlaintiff(){
      let arr = this.courtFilterData["plaintiff"];
      if(Array.isArray(arr)){
        arr  = arr.filter(item=>this.caseInsensitiveSearch(item.key,this.searchData?.plaintiff));
      }
      
      return arr;
    },
    getPlaintiffCount(){
      let arr = this.courtFilterData["plaintiff"]
      let count = 0;
      if(Array.isArray(arr)){
         arr.forEach(item => {if(item.checked){ count++ }});
      }
      return count;
    },
    getDefendant(){
      let arr = this.courtFilterData["defendant"];
      if(Array.isArray(arr)){
        arr  = arr.filter(item=>this.caseInsensitiveSearch(item.key,this.searchData?.defendant));
      }
      return arr;
    },
    getDefendantCount(){
      let arr = this.courtFilterData["defendant"]
      let count = 0;
      if(Array.isArray(arr)){
         arr.forEach(item => {if(item.checked){ count++ }});
      }
      return count;
    },
    getCaseType(){
      let arr = this.courtFilterData["case_type"];
      if(Array.isArray(arr)){
        arr  = arr.filter(item=>this.caseInsensitiveSearch(item.key,this.searchData?.causeOfAction));
      }
      return arr;
    },
    getCaseTypeCount(){
      let arr = this.courtFilterData["case_type"]
      let count = 0;
      if(Array.isArray(arr)){
         arr.forEach(item => {if(item.checked){ count++ }});
      }
      return count;
    },
    getCourtRecords() {
      return this.handleCourt;
    },
  },
  methods: {
    scrollToTop() {
      setTimeout(()=>{
          var container = this.$el.querySelector("#scrollContainer");
          container.scrollTop = 0 ;
          container.scrollIntoView({ behavior: "smooth" });
      },500)
    },
    resetFilter(){
      this.searchData = {
        caseName:"",
        caseType:"",
        plaintiff:"",
        defendant:"",
        causeOfAction:""
      }
      this.$emit('resetCourtFilter')
    },
    updateFrom() {
      if (this.fromDate && this.toDate) {
        const from = new Date(this.fromDate);
        const to = new Date(this.toDate);
        if (from > to) {
          this.dateError = 'From Date must be before To Date';
        } else {
          this.dateError = '';
        }
      }
      this.$emit('update:filling_from_date', this.fromDate);
    },
    updateTo() {
      if (this.fromDate && this.toDate) {
        const from = new Date(this.fromDate);
        const to = new Date(this.toDate);
        if (from > to) {
          this.dateError = 'From Date must be before To Date';
        } else {
          this.dateError = '';
        }
      }
      this.$emit('update:filling_to_date', this.toDate);
    },
    getFillingDate(date){
     return date ? DateTime.fromJSDate(new Date(date)).toFormat('dd-MM-yyyy') : '-';
    },
    dropdownVisible(idx){
     return this.showList[idx]
    },
    caseInsensitiveSearch(targetString, searchString) {
            const regex = new RegExp(searchString, 'i');
            return regex.test(targetString);
        },
    async filterToogle() {
      this.filter = !this.filter;
      // if(this.filter){
      //   this.filterData = await getJudyRecordFilter()
      // }
    },
    riskcolor(item) {
      if (item?.entity_status) {
        return item?.entity_status === "Red" ? "#EB3131" : "#eb9b00";
      } else {
        return "";
      }
    },
    showSeeMore(item) {
      const showCount = this.shrink ? 2 : 3;
      const count = item.risk_categories.length;
      return count > showCount;
    },
    confidenceColor(item) {
      return (item?.confidence_score?.name).toLowerCase();
    },
    getRiskCategories(item) {
      const categories = item.risk_categories || [];
      // const showCount = this.shrink ? 2 : 3;
      // const max = categories.length > showCount ? showCount : categories.length;
      // return this.seeMore ? categories : categories.slice(0, max);
      return categories
    },

    getdescription(item) {
      let courtString = "";
      if (this.name == "court") {
        item.snippet.forEach((i) => (courtString += `${i}, `));
      }
      return `${courtString.substr(0, 200)}...`;
    },
    getStatus(item) {
      return item?.status?.toLowerCase()
        ? item?.status?.toLowerCase()
        : "unknown";
    },
    updateSelectedPage(page) {
      this.$emit("stateClear", page);
    },
    toggleSeeMore() {
      this.seeMore = !this.seeMore;
    },
    formatText(text, maxlen = 40) {
      if (text) {
        let trimmed =
          text.length > maxlen ? text.slice(0, maxlen - 1) + "..." : text;
        return `${trimmed}`;
      }
      return text;
    },
    checkCategories(item) {
      let risk = this.getOptions?.find((v) => v.category == item);
      if (risk) {
        return risk.category_type === "Risk Categories"
          ? item.entity_status === "Amber"
            ? "amberLabel"
            : "redLabel"
          : "greenLabel";
      } else {
        return "redLabel";
      }
    },
    toogleDropdown(idx) {
      this.showList[idx] = !this.showList[idx]
      this.$forceUpdate();
    },
    async selectDropdown(event,idx) {
      this.showList[idx] = false;
      let key = event.target.innerText;
      this.handleCourt[idx].status = key.toUpperCase();
      let payload = { ...this.handleCourt[idx] , case_id: this.$route.query.case_id }
      let data
      data = await updateEntityDetails(payload, this.handleCourt[idx]._id);
      this.$emit("getGlobalFiltersData");
      // this.$emit("pageUpdated");
      if (data) {
        this.$toast.success("Status updated")
      }
    },
    async fetchMoreData() {
      this.loader = true;
      this.handleCourt.forEach((s) =>{
        if(s.source_name != "unicourt")
        {
          let  payload = {
                        case_id: s?.case_id,
                        details_data: [ { doc_id: s?._id }]
                      }
           savejudyData(payload)
        }
      })
     
      let data =  this.handleCourt.filter((s => s.source_name == "unicourt"))
      let promises = data.map((item) => {
        return getPlantifData({ case_id: item?.entity_meta_data?.case_id , query_id :item?.entity_meta_data?.query_id},this.$route.query.case_id);
      });
      let fetchPlantifData = await Promise.allSettled(promises);
      fetchPlantifData = fetchPlantifData.map((item) => {
        if (item.status === "fulfilled") {
          if(item?.value.data.status===false){
            this.CaseError=true
          }
        return item?.value.data.party_array || []
        }
        else{
         return []
        }
      });
      if(this.CaseError){
        this.$toast.error("something went wrong")
      }
      promises = data.map((item) => {
        return getJudgyData({ case_id: item?.entity_meta_data?.case_id , query_id :item?.entity_meta_data?.query_id},this.$route.query.case_id);
      });
      let fetchjudgyData = await Promise.allSettled(promises);
      fetchjudgyData = fetchjudgyData.map((item) => {
        if (item.status === "fulfilled") {
          return item?.value.data.judge_array || []
        }
        else{
          return []
        }
      });

      fetchPlantifData  = fetchPlantifData.map(item => {
        return item.map(party => ({"name":party.name,"party_role":party?.source_party_role}))
      })
      fetchjudgyData  = fetchjudgyData.map(item => {
        return item.map(judgy => ({"name":judgy.name}))
      })
      let unicourt_details_data = [];
      unicourt_details_data =  data.map((item,i) =>{
           return ({
                  "doc_id": item?.entity_meta_data?.doc_id,
                  "unicourt_case_id": item?.entity_meta_data?.case_id,
                  "parties": fetchPlantifData[i],
                  "judges": fetchjudgyData[i],
            })
         })
      let payload = {
                "case_id": this.$route.query.case_id,
                "unicourt_details_data":unicourt_details_data,
         }
      if(unicourt_details_data.length){
        await saveUnicourtData(payload)
      }   
      this.loader = false;
      // getPlantifData();
    },
    getJudgeTitle(item) {
      return item?.entity_meta_data?.judges.map((i) => (`${i.name}`)).join(',') || "";
    },
    getJudge(item) {
      const name  = item?.entity_meta_data?.judges.map((i) => (`${i.name}`)).join(',')|| "";
      if(name && name.length > 25) {
        return name.substr(0,25)+'...';
      } else {
        return name;
      }
    }
  },
  mounted(){
    
  },
  watch: {
    filling_from_date(newValue) {
      this.fromDate = newValue;
    },
    filling_to_date(newValue) {
      this.toDate = newValue;
    },
    handleCourt: {
      handler() {
        this.$nextTick(() => {
          this.scrollToTop();
        });
      },
      deep: true 
    }
  }

};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/color.scss";
.sub_heading{
    color: rgba(40, 40, 40, 0.52);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display:flex;
    align-items:center;
    margin: 5px 0px;
}
.child_container{
    border-radius: 10px;
    background: #F7F8F9;
    padding: 10px;
}
.custom_scroll::-webkit-scrollbar {
  display: none !important;
}
.filter_button {
  border-radius: 8px;
  border: 1px solid #dedede;
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}
.button_red_outline {
  padding: 8px 12px;
  border-color: $lightray_product_color !important;
  border-radius: 6px;
  color: $lightray_product_color !important;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.button_red_outline:hover {
  border-color: $lightray_product_color;
  background-color: $lightray_product_color;
  color: white;
}
.text_heading {
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.text_value {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.label-box {
  display: flex;
  width: 25px;
  height: 24px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.label-box-bg {
  border-radius: 4px;
  background: var(--light-green, #e1f3d3);
}

.minus-box-bg {
  border-radius: 30px;
  background: #d7d7d7;
}

.label {
  color: var(--complete, #67b52b);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cst-buttons-height {
  max-height: 125px;
}

.unknown {
  border-radius: 30px;
  background: #d7d7d7;
}

.confirmed {
  border-radius: 30px;
  background: #f0f8ea;
}

.potential {
  border-radius: 30px;
  background: rgba(252, 103, 19, 0.11);
}

.irrelevant {
  border-radius: 30px;
  background: #fbeded;
}

.high {
  font-size: 12px;
  font-weight: 400;
  color: #67b52b !important;
  background-color: #e1f3d3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  border: 0px solid transparent !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  z-index: 99999 !important;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);
}

ul:hover {
  background-color: white !important;
}

li:hover {
  background-color: #d7d7d7;
}

.custom_card {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.image-section {
  /* max-width: 25px; */
}

.header-link {
  word-break: break-all;
}

.linkHeader {
  margin-top: -13px;
  margin-bottom: 5px;
  font-size: 11px;
  color: gray;
}

.data-section {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
}

.header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100% !important;
}

.description-section {
  word-break: break-all;
}

.amber {
  background-color: rgba(235, 155, 0, 0.2);
  color: #eb9b00;
}

.red {
  background: #ffebeb;
  color: #eb3131;
}

.redLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--light-red, #ffebeb) !important;
  color: var(--red, #eb3131) !important;
}

.amberLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: rgba(235, 155, 0, 0.2) !important;
  color: rgb(235, 155, 0) !important;
}

.greenLabel {
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(5, 159, 39, 0.08) !important;
  color: var(--green, #42b85c) !important;
}

.f-title {
  color: var(--light-ray-main-red, #ce1b22);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

div.flex.flex-col.flex-grow-1 {
  flex-grow: 1 !important;
}
.w-100 {
  width: 100%;
}

.f_title {
  color: var(--light-ray-main-red, #ce1b22);
  // color: "black";
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cst-input-grid {
  display: flex;
  justify-content: space-between;
}

.filters_container {
  max-height: 70vh;
  overflow: scroll;
}
.scroll {
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: #a8b5c9;
        border-radius: 20rem;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #9cadc6;
    }
}
</style>
